import React from 'react';
import styles from './Vault.module.css';
import ExcelTable from '../../Components/ExcelTable';
import ExcelTableEncaissement from '../../Components/ExcelEncaissement';
import ExcelTableDecaissement from '../../Components/ExcelDecaissement';
import ExcelTableAcompte from '../../Components/ExcelAcompte';
import ConstructionAlert from '../../Components/ConstructionAlert/ConstructionAlert';

function Vault() {
  // Sample data for demonstration purposes
  const data = {
    encaissement: [
      { date: '01/10/2023', especes: 726, cheques: 189, tpeBat: 135, sodexo: 345, jumia: 250 },
      { date: '02/10/2023', especes: 653.1, cheques: 90, tpeBat: 500, sodexo: 250, jumia: 250 },
    ],
    decaissement: [
      { date: '01/10/2023', especes: 183, service: 150, divers: 90 },
      { date: '02/10/2023', especes: 200, service: 175, divers: 100 },
    ],
    acomptes: [
      { tanel: 50, tarek: 100 },
      { tanel: 70, tarek: 150 },
    ],
  };

  return (
    <div>
    <div className={styles.vault}>
    <ConstructionAlert />
      <h1>Caisse</h1>

      <div className={styles.container}>
        <div className={styles.section}>
          <h2>Vault</h2>
          <ExcelTable data={data.acomptes} /> {/* Pass acomptes data */}
        </div>
        
        <div className={styles.section}>
          <h2>Encaissement</h2>
          <ExcelTableEncaissement data={data.encaissement} /> {/* Pass encaissement data */}
        </div>

        <div className={styles.section}>
          <h2>Décaissement</h2>
          <ExcelTableDecaissement data={data.decaissement} /> {/* Pass decaissement data */}
        </div>

        <div className={styles.section}>
          <h2>Acomptes</h2>
          <ExcelTableAcompte data={data.acomptes} /> {/* Pass acomptes data */}
        </div>
      </div>
    </div>
       </div>
  );
}

export default Vault;
