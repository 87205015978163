import React from 'react'; // Ensure React is imported
import './ExcelTable.css';

const ExcelPartenaire = ({ data }) => {
  return (
    <div className="table-container">
      <table className="excel-table">
        <thead>
          <tr>
            <th className="header-cell">Partenaire</th>
            <th className="header-cell">Date Début</th>
            <th className="header-cell">Date Fin</th>
            <th className="header-cell">Valeur</th>
            <th className="header-cell">TVA (19%)</th>
            <th className="header-cell">TTC</th>
            <th className="header-cell">Commission</th>
            <th className="header-cell">Montant Recu</th>
            <th className="header-cell">Ecart</th>
          </tr>
        </thead>
        <tbody>
          {data.map((partenaire, partenaireIndex) => {
            const transactionDetails = partenaire.transactions.map(transaction => {
              const commission = transaction.montant * transaction.commission; // Calculate commission
              const tva = transaction.montant * transaction.tva; // Calculate VAT
              const encaissement = transaction.encaissement; // Use the given encaissement
              const montantRecu = transaction.montantRecu; // Use the given montantRecu
              const ecart = montantRecu - encaissement; // Calculate the difference

              return {
                montant: transaction.montant,
                commission,
                tva,
                encaissement,
                montantRecu,
                ecart,
              };
            });

            const totalValue = transactionDetails.reduce((acc, transaction) => acc + transaction.montant, 0);
            const totalCommission = transactionDetails.reduce((acc, transaction) => acc + transaction.commission, 0);
            const totalTva = transactionDetails.reduce((acc, transaction) => acc + transaction.tva, 0);
            const totalEncaissement = transactionDetails.reduce((acc, transaction) => acc + transaction.encaissement, 0);
            const totalMontantRecu = transactionDetails.reduce((acc, transaction) => acc + transaction.montantRecu, 0);
            const totalEcart = totalMontantRecu - totalEncaissement;

            // Find the earliest and latest dates among the transactions
            const earliestDate = partenaire.transactions.reduce((earliest, transaction) => {
              return new Date(transaction.date) < new Date(earliest) ? transaction.date : earliest;
            }, partenaire.transactions[0].date);

            const latestDate = partenaire.transactions.reduce((latest, transaction) => {
              return new Date(transaction.date) > new Date(latest) ? transaction.date : latest;
            }, partenaire.transactions[0].date);

            return (
              <tr key={partenaireIndex} className="data-row">
                <td>{partenaire.name}</td>
                <td>{earliestDate}</td>
                <td>{latestDate}</td>
                <td>{totalValue.toFixed(2)}</td>
                <td>{totalTva.toFixed(2)}</td>
                <td>{(totalValue + totalTva).toFixed(2)}</td> {/* Total amount including VAT */}
                <td>{totalCommission.toFixed(2)}</td>
                <td>{totalMontantRecu.toFixed(2)}</td>
                <td>{totalEcart.toFixed(2)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ExcelPartenaire;
