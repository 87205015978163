import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import styles from './Fournisseur.module.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { fournisseursData } from './DummyDataFounisseur';
import ExcelFournisseur from '../../Components/ExcelFournisseurtable/ExcelFournisseurTable';
import ConstructionAlert from '../../Components/ConstructionAlert/ConstructionAlert';

function FournisseursPage() {
  return (
    
    <div className={styles.FournisseursPage}>
      <ConstructionAlert/>
      <div className={styles.greeting}>
        <h2>Transactions Fournisseurs!</h2>
      </div>
      <ExcelFournisseur data={fournisseursData} />

      <div className={styles.suppliersSection}>
        <h2>Liste des Fournisseurs</h2>
        <div className={styles.statsSection}>
          {fournisseursData.map((fournisseur, index) => (
            <Link 
              to={`/fournisseurs/${fournisseur.name}`} 
              key={index} 
              className={styles.Card}
            >
              <img src={fournisseur.image} alt={fournisseur.name} className={styles.supplierImage} />
              <h3 style={{ color: 'black' , fontWeight: "bold", fontSize:"20px" }}>{fournisseur.name}</h3>
              <p>Status: {fournisseur.status}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FournisseursPage;
