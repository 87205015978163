import './ExcelTable.css';

const ExcelTable2 = ({ data }) => {
  return (
    <div className="table-container">
      <table className="excel-table">
        <thead>
          <tr>
            <th rowSpan="2" className="header-cell">Date</th>
            <th colSpan="5" className="header-cell">ENCAISSEMENT</th>
            <th colSpan="3" className="header-cell">DÉCAISSEMENT</th>
            <th colSpan="2" className="header-cell">ACOMPTES</th>
            <th colSpan="3" className="header-cell">Recap</th>
          </tr>
          <tr>
            <th className="sub-header-cell">Espèces</th>
            <th className="sub-header-cell">Chèques</th>
            <th className="sub-header-cell">TPE BAT</th>
            <th className="sub-header-cell">JUMIA</th>
            <th className="sub-header-cell">SODEXO</th>
            <th className="sub-header-cell">Espèces</th>
            <th className="sub-header-cell">Service</th>
            <th className="sub-header-cell">Divers</th>
            <th className="sub-header-cell">TANEL</th>
            <th className="sub-header-cell">TAREK</th>
            <th className="sub-header-cell">Total</th>
            <th className="sub-header-cell">Rapport</th>
            <th className="sub-header-cell">Ecart</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className="data-row">
              <td>{row.date}</td>
              <td>{row.encaissement.especes}</td>
              <td>{row.encaissement.cheques}</td>
              <td>{row.encaissement.tpeBat}</td>
              <td>{row.encaissement.sodexo}</td>
              <td>{row.encaissement.jumia}</td>
              <td>{row.decaissement.especes}</td>
              <td>{row.decaissement.service}</td>
              <td>{row.decaissement.divers}</td>
              <td>{row.acomptes.tanel}</td>
              <td>{row.acomptes.tarek}</td>
              <td>{row.recap.total}</td>
              <td>{row.recap.rapport}</td>
              <td
                className={`ecart-cell ${row.recap.ecart.startsWith('+') ? 'positive' : row.recap.ecart.startsWith('-') ? 'negative' : ''}`}
              >
                {row.recap.ecart}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      
    </div>
  );
};

export default ExcelTable2;
