import React, { useState } from 'react';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import styles from './Dashboard.module.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { TbLockFilled } from "react-icons/tb";
const data = [
  { name: 'January', uv: 4000, pv: 2400, amt: 2400 },
  { name: 'February', uv: 3000, pv: 1398, amt: 2210 },
  { name: 'March', uv: 2000, pv: 9800, amt: 2290 },
  { name: 'April', uv: 2780, pv: 3908, amt: 2000 },
  { name: 'May', uv: 1890, pv: 4800, amt: 2181 },
  { name: 'June', uv: 2390, pv: 3800, amt: 2500 },
  { name: 'July', uv: 3490, pv: 4300, amt: 2100 },
  
];

// Sample statistics data
const statsData = [
  { title: 'Total CA', value: '20000 TND', icon: <i className="fas fa-coins" style={{ color: "gold" }}></i> },
  { title: 'Total des achats HTVA', value: '1500 TND', icon: <i className="fas fa-money-bill-wave" style={{ color: "green" }}></i> },
  { title: 'Total des achats TTC', value: '1600 TND', icon: <i className="fas fa-receipt" style={{ color: "teal" }}></i> },
  { title: 'TVA déductible', value: '2000 TND', icon: <i className="fas fa-percentage" style={{ color: "purple" }}></i> },
  { title: 'TVA collectée', value: '150 TND', icon: <i className="fas fa-file-invoice-dollar" style={{ color: "darkorange" }}></i> },
  { title: 'Factures non payées', value: '300 TND', icon: <i className="fas fa-exclamation-circle" style={{ color: "red" }}></i> },
  { title: 'Charges salariales', value: '2000 TND', icon: <i className="fas fa-user-tie" style={{ color: "blue" }}></i> },
  { title: 'Global FoodCost', value: '1020 TND', icon: <i className="fas fa-chart-bar" style={{ color: "navy" }}></i> },
];

// Sample suppliers data with images
const fournisseursData = [
  { name: 'Ben Yaghlan', status: 'Payed', image: 'assets/img/BenYaghlen.png' },
  { name: 'Monoprix', status: 'Payed', image: 'assets/img/monoprix.png' },
  { name: 'Legumes', status: '300TND Not Payed', image: 'assets/img/Tomate.png' },
  { name: 'Farine', status: 'Payed', image: 'assets/img/farine.png' },
  { name: 'Boisson', status: '30TND Not Payed', image: 'assets/img/boissons-gazeuses.jpg' },
  { name: 'Electricité', status: '3000TND Not Payed', image: 'assets/img/steg.png' },
];

// Sample partners data with images
const partnersData = [
  { name: 'Jumia', contribution: '5,000 TND', image: 'assets/img/Jumia.png' },
  { name: 'Sodexo', contribution: '3,000 TND', image: 'assets/img/Sodexo.png' },
  { name: 'pluxee', contribution: '2,000 TND', image: 'assets/img/Pluxee.png' },
  { name: 'Glovo', contribution: '4,000 TND', image: 'assets/img/Glovo.png' },
];

function Dashboard() {
  const [showAlert, setShowAlert] = useState(true);

  return (
    <div className={styles.dashboard}>
      {/* Construction Alert */}
      {showAlert && (
        <div className={styles.alertBar}>
          <span className={styles.alertText}>🚧 Ce site est en construction. Restez à l'écoute des mises à jour ! 🚧</span>
          <button className={styles.closeAlert} onClick={() => setShowAlert(false)}>X</button>
        </div>
      )}

      {/* Top Section */}
      <div className={styles.greeting}>
        <h2>Bonjour, StartUpTunisie!</h2>
        <p>Voici votre aperçu de votre entreprise!</p>
      </div>

      {/* Placeholder for Statistics or Cards */}
      <div className={styles.statsSection}>
        {statsData.map((stat, index) => (
          <div className={styles.statCard} key={index} role="figure" aria-label={`Statistics for ${stat.title}`}>
            <span className={styles.statIcon}>{stat.icon}</span>
            <div className={styles.statInfo}>
              <h3>{stat.title}</h3>
              <p>{stat.value}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Sales Overview Section */}
      <div className={styles.salesSection}>
        <div className={styles.salesOverview}>
          <h2>Aperçu des ventes</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pv" fill="#8884d8" />
              <Bar dataKey="uv" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className={styles.schedule}>
          <h2>Tendance des bénéfices</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="pv" stroke="#8884d8" />
              <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Suppliers Section */}
      <div className={styles.suppliersSection}>
        <h2>Fournisseurs</h2>
        <div className={styles.statsSection}>
          {fournisseursData.map((fournisseur, index) => (
            <div className={styles.Card} key={index}>
              <img src={fournisseur.image} alt={fournisseur.name} className={styles.supplierImage} />
              <h3>{fournisseur.name}</h3>
              <p>Status: {fournisseur.status}</p>
            </div>
          ))}
        </div>
        <a class="see-more" href="/fournisseurs">
  Voir Plus  <i className="fas fa-arrow-right" aria-hidden="true" />
</a>

      </div>

      {/* Partners Section */}
      <div className={styles.partnersSection}>
        <h2>Partenaires</h2>
        <div className={styles.statsSection}>
          {partnersData.map((partner, index) => (
            <div className={styles.Card} key={index}>
              <img src={partner.image} alt={partner.name} className={styles.partnerImage} />
              <h3>{partner.name}</h3>
              <p>Contribution: {partner.contribution}</p>
            </div>
          ))}
        </div>
        <a class="see-more" href="/partenaires">
  Voir Plus  <i className="fas fa-arrow-right" aria-hidden="true" />
</a>
      </div>
    </div>
  );
}

export default Dashboard;
