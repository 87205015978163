export const fournisseursData = [
    {
      name: 'Ben Yaghlan',
      status: 'Payed',
      image:  `${process.env.PUBLIC_URL}/assets/img/BenYaghlen.png`,
      transactions: [
        { date: '2024-10-01', fournisseur: 'Ben Yaghlan', HTVA: 50.00, TVA: 10.00, TTC: 60.00, typePayment: 'Credit Card', facture: 'INV-001' },
        { date: '2024-10-02', fournisseur: 'Ben Yaghlan', HTVA: 150.00, TVA: 30.00, TTC: 180.00, typePayment: 'Bank Transfer', facture: 'INV-002' },
        { date: '2024-10-05', fournisseur: 'Ben Yaghlan', HTVA: 200.00, TVA: 40.00, TTC: 240.00, typePayment: 'Cash', facture: 'INV-003' },
        { date: '2024-10-10', fournisseur: 'Ben Yaghlan', HTVA: 120.00, TVA: 24.00, TTC: 144.00, typePayment: 'Credit Card', facture: 'INV-004' },
      ],
    },
    {
      name: 'Monoprix',
      status: 'Payed',
      image:  `${process.env.PUBLIC_URL}/assets/img/monoprix.png`,
      transactions: [
        { date: '2024-10-02', fournisseur: 'Monoprix', HTVA: 60.00, TVA: 12.00, TTC: 72.00, typePayment: 'Cash', facture: 'INV-005' },
        { date: '2024-10-03', fournisseur: 'Monoprix', HTVA: 135.00, TVA: 27.00, TTC: 162.00, typePayment: 'Credit Card', facture: 'INV-006' },
        { date: '2024-10-07', fournisseur: 'Monoprix', HTVA: 85.00, TVA: 17.00, TTC: 102.00, typePayment: 'Bank Transfer', facture: 'INV-007' },
        { date: '2024-10-15', fournisseur: 'Monoprix', HTVA: 50.00, TVA: 10.00, TTC: 60.00, typePayment: 'Cash', facture: 'INV-008' },
      ],
    },
    {
      name: 'Tomate',
      status: '300Dt Not Payed',
      image:  `${process.env.PUBLIC_URL}/assets/img/Tomate.png`,
      transactions: [
        { date: '2024-10-02', fournisseur: 'Tomate', HTVA: 60.00, TVA: 12.00, TTC: 72.00, typePayment: 'Pending', facture: 'INV-009' },
        { date: '2024-10-03', fournisseur: 'Tomate', HTVA: 135.00, TVA: 27.00, TTC: 162.00, typePayment: 'Pending', facture: 'INV-010' },
        { date: '2024-10-06', fournisseur: 'Tomate', HTVA: 100.00, TVA: 20.00, TTC: 120.00, typePayment: 'Pending', facture: 'INV-011' },
      ],
    },
    {
      name: 'Farine',
      status: 'Payed',
      image:  `${process.env.PUBLIC_URL}/assets/img/farine.png`,
      transactions: [
        { date: '2024-10-02', fournisseur: 'Farine', HTVA: 60.00, TVA: 12.00, TTC: 72.00, typePayment: 'Cash', facture: 'INV-012' },
        { date: '2024-10-04', fournisseur: 'Farine', HTVA: 135.00, TVA: 27.00, TTC: 162.00, typePayment: 'Bank Transfer', facture: 'INV-013' },
        { date: '2024-10-08', fournisseur: 'Farine', HTVA: 95.00, TVA: 19.00, TTC: 114.00, typePayment: 'Credit Card', facture: 'INV-014' },
      ],
    },
    {
      name: 'Boisson',
      status: '30Dt Not Payed',
      image:  `${process.env.PUBLIC_URL}/assets/img/boissons-gazeuses.jpg`,
      transactions: [
        { date: '2024-10-02', fournisseur: 'Boisson', HTVA: 60.00, TVA: 12.00, TTC: 72.00, typePayment: 'Pending', facture: 'INV-015' },
        { date: '2024-10-05', fournisseur: 'Boisson', HTVA: 135.00, TVA: 27.00, TTC: 162.00, typePayment: 'Pending', facture: 'INV-016' },
        { date: '2024-10-09', fournisseur: 'Boisson', HTVA: 120.00, TVA: 24.00, TTC: 144.00, typePayment: 'Cash', facture: 'INV-017' },
      ],
    },
    {
      name: 'Electricité',
      status: '3000Dt Not Payed',
      image:  `${process.env.PUBLIC_URL}/assets/img/steg.png`,
      transactions: [
        { date: '2024-10-02', fournisseur: 'Electricité', HTVA: 60.00, TVA: 12.00, TTC: 72.00, typePayment: 'Pending', facture: 'INV-018' },
        { date: '2024-10-06', fournisseur: 'Electricité', HTVA: 135.00, TVA: 27.00, TTC: 162.00, typePayment: 'Pending', facture: 'INV-019' },
        { date: '2024-10-10', fournisseur: 'Electricité', HTVA: 180.00, TVA: 36.00, TTC: 216.00, typePayment: 'Pending', facture: 'INV-020' },
        { date: '2024-10-12', fournisseur: 'Electricité', HTVA: 300.00, TVA: 60.00, TTC: 360.00, typePayment: 'Bank Transfer', facture: 'INV-021' },
      ],
    },
  ];
  