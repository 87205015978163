import React from 'react';
import './ExcelTable.css';

const ExcelTable4 = ({ data, onEdit, onDelete }) => {
  return (
    <div className="table-container">
      <table className="excel-table">
        <thead>
          <tr>
            <th rowSpan="2" className="header-cell">Date</th>
            <th colSpan="5" className="header-cell">ENCAISSEMENT</th>
            
          </tr>
          <tr>
            <th className="sub-header-cell">Espèces</th>
            <th className="sub-header-cell">Chèques</th>
            <th className="sub-header-cell">TPE BAT</th>
            <th className="sub-header-cell">JUMIA</th>
            <th className="sub-header-cell">SODEXO</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className="data-row">
              <td>{row.date}</td>
              <td>{row.encaissement.especes}</td>
              <td>{row.encaissement.cheques}</td>
              <td>{row.encaissement.tpeBat}</td>
              <td>{row.encaissement.jumia}</td>
              <td>{row.encaissement.sodexo}</td>
           
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExcelTable4;
