import './ExcelTable.css';
import { TbLockFilled } from "react-icons/tb";
const ExcelTable = () => {
  const data = [
    {
      date: '01/10/2023',
      encaissement: { especes: 726, cheques: 189, tpeBat: 135, sodexo: 345, jumia: 250 },
      decaissement: { especes: 183, service: 150, divers: 90 },
      acomptes: { tanel: 50, tarek: 100 },
      recap: { total: 300, rapport: 50, ecart: "-0.20" },
    },
    {
      date: '02/10/2023',
      encaissement: { especes: 653.1, cheques: 90, tpeBat: 500, sodexo: 250, jumia: 250 },
      decaissement: { especes: 200, service: 175, divers: 100 },
      acomptes: { tanel: 70, tarek: 150 },
      recap: { total: 350, rapport: 70, ecart: "+0.25" },
    },
    // Add more rows with similar data to match the table structure
  ];

  return (
    <div className="table-container">
      <table className="excel-table">
        <thead>
          <tr>
            <th rowSpan="2" className="header-cell">Date</th>
            <th colSpan="5" className="header-cell">ENCAISSEMENT</th>
            <th colSpan="3" className="header-cell">DÉCAISSEMENT</th>
            <th colSpan="2" className="header-cell">ACOMPTES</th>
            <th colSpan="3" className="header-cell">Recap</th>
          </tr>
          <tr>
            <th className="sub-header-cell">Espèces</th>
            <th className="sub-header-cell">Chèques</th>
            <th className="sub-header-cell">TPE BAT</th>
            <th className="sub-header-cell">JUMIA</th>
            <th className="sub-header-cell">SODEXO</th>
            <th className="sub-header-cell">Espèces</th>
            <th className="sub-header-cell">Service</th>
            <th className="sub-header-cell">Divers</th>
            <th className="sub-header-cell">TANEL</th>
            <th className="sub-header-cell">TAREK</th>
            <th className="sub-header-cell">Total</th>
            <th className="sub-header-cell">Rapport</th>
            <th className="sub-header-cell">Ecart</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className="data-row">
              <td>{row.date}</td>
              <td>{row.encaissement.especes}</td>
              <td>{row.encaissement.cheques}</td>
              <td>{row.encaissement.tpeBat}</td>
              <td>{row.encaissement.sodexo}</td>
              <td>{row.encaissement.jumia}</td>
              <td>{row.decaissement.especes}</td>
              <td>{row.decaissement.service}</td>
              <td>{row.decaissement.divers}</td>
              <td>{row.acomptes.tanel}</td>
              <td>{row.acomptes.tarek}</td>
              <td>{row.recap.total}</td>
              <td>{row.recap.rapport}</td>
              <td
                className={`ecart-cell ${row.recap.ecart.startsWith('+') ? 'positive' : row.recap.ecart.startsWith('-') ? 'negative' : ''}`}
              >
                {row.recap.ecart}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <a className="see-more" href="/AllTransactionsVault">

Voir Plus
<i className="fas fa-arrow-right" aria-hidden="true" />
</a>
    </div>
  );
};

export default ExcelTable;
