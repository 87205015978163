import React, { useState } from 'react';
import styles from './ConstructionAlert.module.css'; // Create the CSS for the alert

function ConstructionAlert() {
  const [showAlert, setShowAlert] = useState(true);

  return (
    showAlert && (
      <div className={styles.alertBar}>
        <span className={styles.alertText}>🚧 This site is still under construction. Stay tuned for updates! 🚧</span>
        <button className={styles.closeAlert} onClick={() => setShowAlert(false)}>X</button>
      </div>
    )
  );
}

export default ConstructionAlert;
