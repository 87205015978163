export const partnersData = [
  {
    name: 'Jumia',
    image: `${process.env.PUBLIC_URL}/assets/img/Jumia.png`,
    contribution: '5,000 Dt',
    transactions: [
      {
        date: '2024-01-01',
        montant: 500.00,
        commissionRate: 0.1, // 10% commission
        tvaRate: 0.19, // 19% VAT
        droitsDeTimbre: 1,
        montantRecu: 485.00,
      },
      {
        date: '2024-01-05',
        montant: 500.00,
        commissionRate: 0.10,
        tvaRate: 0.19,
        droitsDeTimbre: 1,
        montantRecu: 490.00,
      },
    ],
  },
  {
    name: 'Sodexo',
    image: `${process.env.PUBLIC_URL}/assets/img/Sodexo.png`,
    contribution: '3,000 Dt',
    transactions: [
      {
        date: '2024-01-03',
        montant: 150.00,
        commissionRate: 0.10,
        tvaRate: 0.19,
        droitsDeTimbre: 1,
        montantRecu: 100,
      },
      {
        date: '2024-01-06',
        montant: 100.00,
        commissionRate: 0.10,
        tvaRate: 0.19,
        droitsDeTimbre: 1,
        montantRecu: 94.00,
      },
    ],
  },
  {
    name: 'Pluxee',
    image: `${process.env.PUBLIC_URL}/assets/img/Pluxee.png`,
    contribution: '2,000 Dt',
    transactions: [
      {
        date: '2024-01-02',
        montant: 600.00,
        commissionRate: 0.10,
        tvaRate: 0.19,
        droitsDeTimbre: 4,
        montantRecu: 574.00,
      },
      {
        date: '2024-01-07',
        montant: 480.00,
        commissionRate: 0.10,
        tvaRate: 0.19,
        droitsDeTimbre: 4,
        montantRecu: 464.00,
      },
    ],
  },
  {
    name: 'Glovo',
    image: `${process.env.PUBLIC_URL}/assets/img/Glovo.png`,
    contribution: '4,000 Dt',
    transactions: [
      {
        date: '2024-01-04',
        montant: 105.00,
        commissionRate: 0.10,
        tvaRate: 0.19,
        droitsDeTimbre: 1,
        montantRecu: 102.00,
      },
      {
        date: '2024-01-08',
        montant: 600.00,
        commissionRate: 0.10,
        tvaRate: 0.19,
        droitsDeTimbre: 5,
        montantRecu: 585.00,
      },
    ],
  },
];

// Function to calculate additional transaction details
const calculateTransactionDetails = (transaction) => {
  // Ensure montant and commissionRate are numbers
  const montant = Number(transaction.montant);
  const commissionRate = Number(transaction.commissionRate);
  const tvaRate = Number(transaction.tvaRate);
  const droitsDeTimbre = Number(transaction.droitsDeTimbre);

  const commission = (commissionRate / commissionRate); // Calculate commission
  const tva = commission * tvaRate; // Calculate VAT on commission
  const encaissement = montant - commission - tva - droitsDeTimbre; // Total collected
  const ecart = transaction.montantRecu - encaissement; // Difference

  return { commission, tva, encaissement, ecart };
};

// Update transactions with calculated values
partnersData.forEach(partenaire => {
  partenaire.transactions.forEach(transaction => {
    const { commission, tva, encaissement, ecart } = calculateTransactionDetails(transaction);
    transaction.commission = commission; // Store calculated commission
    transaction.tva = tva;               // Store calculated VAT
    transaction.encaissement = encaissement; // Store calculated total collected
    transaction.ecart = ecart;           // Store calculated difference
  });
});

// Log the updated data
console.log(partnersData);
