import React, { useState } from 'react';
import styles from './PopUp.module.css';

function Modal({ isOpen, onClose, onSubmit }) {
  const predefinedTypes = ['Type 1', 'Type 2', 'Type 3'];

  const [formValues, setFormValues] = useState({
    date: '',
    especes: '',
    cheques: '',
    tpeBat: '',
    sodexo: '',
    jumia: '',
    type: '',
    customType: '',
  });

  const [isCustomType, setIsCustomType] = useState(false);

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      date: formValues.date,
      encaissement: {
        especes: Number(formValues.especes) || 0,
        cheques: Number(formValues.cheques) || 0,
        tpeBat: Number(formValues.tpeBat) || 0,
        sodexo: Number(formValues.sodexo) || 0,
        jumia: Number(formValues.jumia) || 0,
      },
      type: isCustomType ? formValues.customType : formValues.type,
    };

    onSubmit(formData);
    handleClose();
  };

  const handleClose = () => {
    setFormValues({
      date: '',
      especes: '',
      cheques: '',
      tpeBat: '',
      sodexo: '',
      jumia: '',
      type: '',
      customType: '',
    });
    setIsCustomType(false);
    onClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleTypeChange = (e) => {
    const selectedType = e.target.value;
    if (selectedType === 'Other') {
      setIsCustomType(true);
      setFormValues((prev) => ({ ...prev, type: '', customType: '' }));
    } else {
      setIsCustomType(false);
      setFormValues((prev) => ({ ...prev, type: selectedType }));
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={handleClose}>
          <i className="fas fa-times"></i>
        </button>
        <h2>Ajouter Encaissement</h2>
        <form onSubmit={handleSubmit} className={styles.form}>
          <label>Date:</label>
          <input type="date" name="date" value={formValues.date} onChange={handleChange} required />

          <h3>Encaissement</h3>
          <label>Espèces:</label>
          <input type="number" name="especes" value={formValues.especes} onChange={handleChange} min="0" required />
          <label>Chèques:</label>
          <input type="number" name="cheques" value={formValues.cheques} onChange={handleChange} min="0" />
          <label>Sodexo:</label>
          <input type="number" name="sodexo" value={formValues.sodexo} onChange={handleChange} min="0" />
          <label>Jumia:</label>
          <input type="number" name="jumia" value={formValues.jumia} onChange={handleChange} min="0" />

          <h3>Type</h3>
          <label>Choisissez un type:</label>
          <select name="type" value={formValues.type} onChange={handleTypeChange} required>
            <option value="">Sélectionner un type</option>
            {predefinedTypes.map((type, index) => (
              <option key={index} value={type}>{type}</option>
            ))}
            <option value="Other">Autre</option>
          </select>

          {isCustomType && (
            <div>
              <label>Type personnalisé:</label>
              <input
                type="text"
                name="customType"
                value={formValues.customType}
                onChange={handleChange}
                required
              />
            </div>
          )}

          <button type="submit">Ajouter</button>
        </form>
      </div>
    </div>
  );
}

export default Modal;
