import React, { useState } from 'react';
import './ExcelTable.css';

const ExcelFournisseur = ({ data }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Filter suppliers based on the search query and date range
  const filteredData = data
    .filter(fournisseur =>
      fournisseur.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .map(fournisseur => ({
      ...fournisseur,
      transactions: fournisseur.transactions.filter(transaction => {
        const transactionDate = new Date(transaction.date);
        const isAfterStartDate = startDate ? transactionDate >= new Date(startDate) : true;
        const isBeforeEndDate = endDate ? transactionDate <= new Date(endDate) : true;
        return isAfterStartDate && isBeforeEndDate;
      })
    }))
    .filter(fournisseur => fournisseur.transactions.length > 0); // Ensure suppliers with no transactions in the range are removed

  return (
    <div className="table-container">
      <input
        type="text"
        placeholder="Rechercher un fournisseur..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-input"
      />
      <input
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        className="date-input"
      />
      <input
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        className="date-input"
      />
      <table className="excel-table">
        <thead>
          <tr>
            <th className="header-cell">Fournisseur</th>
            <th className="header-cell">Date</th>
            <th className="header-cell">Montant HTVA</th>
            <th className="header-cell">TVA</th>
            <th className="header-cell">TTC</th>
            <th className="header-cell">Type de Paiement</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((fournisseur, fournisseurIndex) => (
            <React.Fragment key={fournisseurIndex}>
              {fournisseur.transactions.map((transaction, transactionIndex) => (
                <tr key={`${fournisseurIndex}-${transactionIndex}`} className="data-row">
                  {transactionIndex === 0 && (
                    <td rowSpan={fournisseur.transactions.length} className="fournisseur-name">
                      {fournisseur.name}
                    </td>
                  )}
                  <td>{transaction.date}</td>
                  <td>{transaction.HTVA.toFixed(2)}</td>
                  <td>{transaction.TVA.toFixed(2)}</td>
                  <td>{transaction.TTC.toFixed(2)}</td>
                  <td>{transaction.typePayment}</td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExcelFournisseur;
