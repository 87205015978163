import './ExcelTable.css';
import { TbLockFilled } from "react-icons/tb";

const ExcelTableEncaissement = () => {
  const data = [
    {
      date: '01/10/2023',
      encaissement: { especes: 726, cheques: 189, tpeBat: 135, sodexo: 345, jumia: 250 },
    
    },
    {
      date: '02/10/2023',
      encaissement: { especes: 653.1, cheques: 90, tpeBat: 500, sodexo: 250, jumia: 250 },
     
    },
    // Add more rows with similar data to match the table structure
  ];

  return (
    <div className="table-container">
      <table className="excel-table">
        <thead>
          <tr>
            <th rowSpan="2" className="header-cell">Date</th>
            <th colSpan="5" className="header-cell">ENCAISSEMENT</th>
            
          </tr>
          <tr>
            <th className="sub-header-cell">Espèces</th>
            <th className="sub-header-cell">Chèques</th>
            <th className="sub-header-cell">TPE BAT</th>
            <th className="sub-header-cell">JUMIA</th>
            <th className="sub-header-cell">SODEXO</th>
            
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className="data-row">
              <td>{row.date}</td>
              <td>{row.encaissement.especes}</td>
              <td>{row.encaissement.cheques}</td>
              <td>{row.encaissement.tpeBat}</td>
              <td>{row.encaissement.sodexo}</td>
              <td>{row.encaissement.jumia}</td>
              
           
            </tr>
          ))}
        </tbody>
      </table>
  
      <a className="see-more" href="/Enciasse">
        Voir Plus
        <i className="fas fa-arrow-right" aria-hidden="true" />
      </a>
    </div>
  );
};

export default ExcelTableEncaissement;
