import './ExcelTable.css';

const ExcelTable3 = ({ data }) => {
  return (
    <div className="table-container">
      <table className="excel-table">
        <thead>
          <tr>
            <th rowSpan="2" className="header-cell">Date</th>
           
            <th colSpan="3" className="header-cell">DÉCAISSEMENT</th>
           
          </tr>
          <tr>
           
            <th className="sub-header-cell">Espèces</th>
            <th className="sub-header-cell">Service</th>
            <th className="sub-header-cell">Divers</th>
            
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className="data-row">
              <td>{row.date}</td>
             
              <td>{row.decaissement.especes}</td>
              <td>{row.decaissement.service}</td>
              <td>{row.decaissement.divers}</td>
             
              
            </tr>
          ))}
        </tbody>
      </table>
      
    </div>
  );
};

export default ExcelTable3;
