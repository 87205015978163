
import React from 'react';


// Sample data for ingredients and products
export const initialIngredients = [
  { id: 1, name: 'Tomato', cost: 2, unit: 'Kg' },
  { id: 2, name: 'Cheese', cost: 10, unit: 'Kg' },
  { id: 3, name: 'Lettuce', cost: 1.5, unit: 'Kg' },
  { id: 4, name: 'Chicken Breast', cost: 8, unit: 'Kg' },
  { id: 5, name: 'Beef Patty', cost: 12, unit: 'Kg' },
  { id: 6, name: 'BBQ Sauce', cost: 5, unit: 'L' },
  { id: 7, name: 'Caesar Dressing', cost: 6, unit: 'L' },
  { id: 8, name: 'Basil', cost: 15, unit: 'Kg' },
  { id: 9, name: 'Cucumber', cost: 1.8, unit: 'Kg' },
  { id: 10, name: 'Avocado', cost: 15, unit: 'Kg' },
  { id: 11, name: 'Carrot', cost: 1.2, unit: 'Kg' },
  { id: 12, name: 'Quinoa', cost: 7, unit: 'Kg' },
  { id: 13, name: 'Bacon', cost: 18, unit: 'Kg' },
  { id: 14, name: 'Cream Sauce', cost: 4, unit: 'L' },
  { id: 15, name: 'Turkey Breast', cost: 9, unit: 'Kg' },
  { id: 16, name: 'Salmon', cost: 20, unit: 'Kg' },
  { id: 17, name: 'Rice', cost: 1, unit: 'Kg' },
  { id: 18, name: 'Seaweed', cost: 25, unit: 'Kg' },
  { id: 19, name: 'Bell Pepper', cost: 3, unit: 'Kg' },
  { id: 20, name: 'Broccoli', cost: 4, unit: 'Kg' },
  { id: 21, name: 'Soy Sauce', cost: 3, unit: 'L' },
  { id: 22, name: 'Toast', cost: 1, unit: 'Kg' },
  { id: 23, name: 'Egg', cost: 0.5, unit: 'piece' },
  { id: 24, name: 'Shrimp', cost: 25, unit: 'Kg' },
  { id: 25, name: 'Noodles', cost: 2.5, unit: 'Kg' },
  { id: 26, name: 'Peanuts', cost: 10, unit: 'Kg' },
  { id: 27, name: 'Spinach', cost: 4, unit: 'Kg' },
  { id: 28, name: 'Feta Cheese', cost: 12, unit: 'Kg' },
  { id: 29, name: 'Falafel', cost: 8, unit: 'Kg' },
  { id: 30, name: 'Hummus', cost: 6, unit: 'Kg' },
  { id: 31, name: 'Pita', cost: 2, unit: 'piece' },
  { id: 32, name: 'Chickpeas', cost: 3, unit: 'Kg' },
  { id: 33, name: 'Coconut Milk', cost: 5, unit: 'L' },
  { id: 34, name: 'Curry Paste', cost: 7, unit: 'Kg' },
  { id: 35, name: 'Pasta', cost: 2, unit: 'Kg' },
];

export const initialProducts = [
  {
    id: 1,
    name: 'Cheese Burger',
    image: `${process.env.PUBLIC_URL}/assets/img/Products/cheeseburger.jpg`,
    ingredients: [
      { name: 'Tomato', quantity: 0.2 },
      { name: 'Cheese', quantity: 0.3 },
      { name: 'Lettuce', quantity: 0.1 },
    ],
  },
  {
    id: 2,
    name: 'Veggie Wrap',
    image: `${process.env.PUBLIC_URL}/assets/img/Products/Veggie-Wrap.jpg`,
    ingredients: [
      { name: 'Tomato', quantity: 0.2 },
      { name: 'Lettuce', quantity: 0.3 },
      { name: 'Cucumber', quantity: 0.1 },
    ],
  },
  {
    id: 3,
    name: 'Grilled Chicken Sandwich',
    image: `${process.env.PUBLIC_URL}/assets/img/Products/grilled-chicken-sandwich.jpg`,
    ingredients: [
      { name: 'Chicken Breast', quantity: 0.25 },
      { name: 'Lettuce', quantity: 0.1 },
      { name: 'Tomato', quantity: 0.1 },
    ],
  },
  {
    id: 4,
    name: 'BBQ Beef Burger',
    image: `${process.env.PUBLIC_URL}/assets/img/Products/BBQ-Beef-Burger.jpg`,
    ingredients: [
      { name: 'Beef Patty', quantity: 0.3 },
      { name: 'BBQ Sauce', quantity: 0.05 },
      { name: 'Cheese', quantity: 0.2 },
    ],
  },
  {
    id: 5,
    name: 'Chicken Caesar Salad',
    image: `${process.env.PUBLIC_URL}/assets/img/Products/caesar_salad.jpg`,
    ingredients: [
      { name: 'Chicken Breast', quantity: 0.2 },
      { name: 'Lettuce', quantity: 0.3 },
      { name: 'Caesar Dressing', quantity: 0.05 },
    ],
  },
  {
    id: 6,
    name: 'Margarita Pizza',
    image: `${process.env.PUBLIC_URL}/assets/img/Products/margarita_pizza.jpg`,
    ingredients: [
      { name: 'Tomato Sauce', quantity: 0.1 },
      { name: 'Cheese', quantity: 0.25 },
      { name: 'Basil', quantity: 0.05 },
    ],
  },
  {
    id: 7,
    name: 'Beef Tacos',
    image: `../assets/img/Products/beef_tacos.jpg`,
    ingredients: [
      { name: 'Beef', quantity: 0.15 },
      { name: 'Lettuce', quantity: 0.1 },
      { name: 'Tomato', quantity: 0.1 },
    ],
  },
  {
    id: 8,
    name: 'Vegan Buddha Bowl',
    image: `${process.env.PUBLIC_URL}/assets/img/Products/buddha_bowl.jpg`,
    ingredients: [
      { name: 'Quinoa', quantity: 0.2 },
      { name: 'Avocado', quantity: 0.15 },
      { name: 'Carrot', quantity: 0.1 },
    ],
  },
  {
    id: 9,
    name: 'Pasta Carbonara',
    image: `${process.env.PUBLIC_URL}/assets/img/Products/carbonara.jpg`,
    ingredients: [
      { name: 'Pasta', quantity: 0.3 },
      { name: 'Bacon', quantity: 0.1 },
      { name: 'Cream Sauce', quantity: 0.15 },
    ],
  },
  {
    id: 10,
    name: 'Turkey Sandwich',
    image: `${process.env.PUBLIC_URL}/assets/img/Products/turkey_sandwich.jpg`,
    ingredients: [
      { name: 'Turkey Breast', quantity: 0.25 },
      { name: 'Lettuce', quantity: 0.1 },
      { name: 'Cheese', quantity: 0.05 },
    ],
  },
  {
    id: 11,
    name: 'Salmon Sushi Roll',
    image: `${process.env.PUBLIC_URL}/assets/img/Products/sushi_roll.jpg`,
    ingredients: [
      { name: 'Salmon', quantity: 0.15 },
      { name: 'Rice', quantity: 0.2 },
      { name: 'Seaweed', quantity: 0.05 },
    ],
  },
  {
    id: 12,
    name: 'Vegetable Stir Fry',
    image: `${process.env.PUBLIC_URL}/assets/img/Products/veggie_stir_fry.jpg`,
    ingredients: [
      { name: 'Bell Pepper', quantity: 0.1 },
      { name: 'Broccoli', quantity: 0.1 },
      { name: 'Soy Sauce', quantity: 0.05 },
    ],
  },
  {
    id: 13,
    name: 'Avocado Toast',
    image: `${process.env.PUBLIC_URL}/assets/img/Products/avocado_toast.jpg`,
    ingredients: [
      { name: 'Avocado', quantity: 0.15 },
      { name: 'Toast', quantity: 0.1 },
      { name: 'Egg', quantity: 0.1 },
    ],
  },
  {
    id: 14,
    name: 'Shrimp Pad Thai',
    image: `${process.env.PUBLIC_URL}/assets/img/Products/pad_thai.jpg`,
    ingredients: [
      { name: 'Shrimp', quantity: 0.2 },
      { name: 'Noodles', quantity: 0.15 },
      { name: 'Peanuts', quantity: 0.05 },
    ],
  },
  {
    id: 15,
    name: 'Spinach and Feta Wrap',
    image: `${process.env.PUBLIC_URL}/assets/img/Products/spinach_wrap.jpg`,
    ingredients: [
      { name: 'Spinach', quantity: 0.2 },
      { name: 'Feta Cheese', quantity: 0.1 },
      { name: 'Wrap', quantity: 0.1 },
    ],
  },
  {
    id: 16,
    name: 'Tuna Salad',
    image: `${process.env.PUBLIC_URL}/assets/img/Products/tuna_salad.jpg`,
    ingredients: [
      { name: 'Tuna', quantity: 0.2 },
      { name: 'Lettuce', quantity: 0.2 },
      { name: 'Tomato', quantity: 0.1 },
    ],
  },
  {
    id: 17,
    name: 'Chickpea Curry',
    image: `${process.env.PUBLIC_URL}/assets/img/Products/chickpea_curry.jpg`,
    ingredients: [
      { name: 'Chickpeas', quantity: 0.2 },
      { name: 'Coconut Milk', quantity: 0.1 },
      { name: 'Curry Paste', quantity: 0.05 },
    ],
  },
  {
    id: 18,
    name: 'Falafel Plate',
    image: `${process.env.PUBLIC_URL}/assets/img/Products/falafel_plate.jpg`,
    ingredients: [
      { name: 'Falafel', quantity: 0.3 },
      { name: 'Hummus', quantity: 0.1 },
      { name: 'Pita', quantity: 0.1 },
    ],
  },
  {
    id: 19,
    name: 'BBQ Chicken Pizza',
    image: `${process.env.PUBLIC_URL}/assets/img/Products/bbq_pizza.jpg`,
    ingredients: [
      { name: 'Chicken Breast', quantity: 0.2 },
      { name: 'BBQ Sauce', quantity: 0.1 },
      { name: 'Cheese', quantity: 0.25 },
    ],
  },
  {
    id: 20,
    name: 'Beef Burrito',
    image: `${process.env.PUBLIC_URL}/assets/img/Products/beef_burrito.jpg`,
    ingredients: [
      { name: 'Beef', quantity: 0.25 },
      { name: 'Beans', quantity: 0.1 },
      { name: 'Cheese', quantity: 0.1 },
    ],
  },
];