import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { partnersData } from './DummyDataPartenersDetails'; // Ensure this path is correct
import styles from './PartenaireDetailsPage.module.css'; // Ensure this path is correct
import { FaCalendarAlt, FaMoneyBillWave, FaInfoCircle, FaEdit, FaTrash, FaFileInvoice } from 'react-icons/fa';
import ConstructionAlert from '../../../Components/ConstructionAlert/ConstructionAlert';

function PartnerDetailsPage() {
  const { name } = useParams();
  const partenaire = partnersData.find((item) => item.name === name);

  const [transactions, setTransactions] = useState(partenaire ? partenaire.transactions : []);
  const [formData, setFormData] = useState({ date: '', invoiceNumber: '', amount: '', typePayment: '' });
  const [editIndex, setEditIndex] = useState(null);

  if (!partenaire) {
    return <div className={styles.notFound}>Partenaire not found</div>;
  }

  // Handle input change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Add new transaction
  const addTransaction = () => {
    if (!formData.date || !formData.invoiceNumber || !formData.amount || !formData.typePayment) {
      alert("Please fill in all fields.");
      return;
    }
    setTransactions([...transactions, formData]);
    setFormData({ date: '', invoiceNumber: '', amount: '', typePayment: '' });
  };

  // Edit an existing transaction
  const editTransaction = (index) => {
    setFormData(transactions[index]);
    setEditIndex(index);
  };

  // Update transaction after editing
  const updateTransaction = () => {
    if (!formData.date || !formData.invoiceNumber || !formData.amount || !formData.typePayment) {
      alert("Please fill in all fields.");
      return;
    }
    const updatedTransactions = transactions.map((transaction, index) =>
      index === editIndex ? formData : transaction
    );
    setTransactions(updatedTransactions);
    setFormData({ date: '', invoiceNumber: '', amount: '', typePayment: '' });
    setEditIndex(null);
  };

  // Delete transaction
  const deleteTransaction = (index) => {
    const updatedTransactions = transactions.filter((_, i) => i !== index);
    setTransactions(updatedTransactions);
  };

  return (
    <div className={styles.detailsPage}>
      <ConstructionAlert/>
      <div className={styles.header}>
        <h2>Details for {partenaire.name}</h2>
        <img src={partenaire.image} alt={partenaire.name} className={styles.image} />
      </div>

      <div className={styles.statusCard}>
        <p className={styles.status}>Status: {partenaire.status}</p>
      </div>

      <div className={styles.transactionsSection}>
        <h3>Transactions</h3>
        <div className={styles.transactions}>
          {transactions.map((transaction, index) => (
            <div className={styles.transactionCard} key={index}>
              <p><FaCalendarAlt /> Date: {transaction.date}</p>
              <p><FaFileInvoice /> Invoice Number: {transaction.invoiceNumber}</p>
              <p><FaMoneyBillWave /> Amount: {transaction.amount}</p>
              <p><FaInfoCircle /> Payment Type: {transaction.typePayment}</p>
              
              <div className={styles.actions}>
                <button onClick={() => editTransaction(index)}><FaEdit /> Edit</button>
                <button onClick={() => deleteTransaction(index)}><FaTrash /> Delete</button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.formSection}>
        <h3>{editIndex !== null ? "Edit Transaction" : "Add Transaction"}</h3>
        <form onSubmit={(e) => e.preventDefault()} className={styles.form}>
          <label>
            Date:
            <input type="text" name="date" value={formData.date} onChange={handleChange} />
          </label>
          <label>
            Invoice Number:
            <input type="text" name="invoiceNumber" value={formData.invoiceNumber} onChange={handleChange} />
          </label>
          <label>
            Amount:
            <input type="number" name="amount" value={formData.amount} onChange={handleChange} />
          </label>
          <label>
            Payment Type:
            <input type="text" name="typePayment" value={formData.typePayment} onChange={handleChange} />
          </label>
          <button type="button" onClick={editIndex !== null ? updateTransaction : addTransaction}>
            {editIndex !== null ? "Update Transaction" : "Add Transaction"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default PartnerDetailsPage;
