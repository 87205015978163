export const fournisseursData = [
  {
    name: 'Ben Yaghlan',
    status: 'Payed',
    image: `${process.env.PUBLIC_URL}assets/img/BenYaghlen.png`,
    transactions: [
      { date: '2024-10-01', fournisseur: 'Ben Yaghlan', HTVA: 50.00, TVA: 10.00, TTC: 60.00, typePayment: 'Credit Card' },
      { date: '2024-10-01', fournisseur: 'Ben Yaghlan', HTVA: 150.00, TVA: 30.00, TTC: 180.00, typePayment: 'Bank Transfer' },
    ],
  },
  {
    name: 'Monoprix',
    status: 'Payed',
    image: `${process.env.PUBLIC_URL}/assets/img/Monoprix.png`,
    transactions: [
      { date: '2024-10-02', fournisseur: 'Monoprix', HTVA: 60.00, TVA: 12.00, TTC: 72.00, typePayment: 'Cash' },
      { date: '2024-10-02', fournisseur: 'Monoprix', HTVA: 135.00, TVA: 27.00, TTC: 162.00, typePayment: 'Credit Card' },
    ],
  },
  {
    name: 'Legume',
    status: '300Dt Not Payed',
    image:    `${process.env.PUBLIC_URL}/assets/img/Tomate.png`,
    transactions: [
      { date: '2024-10-02', fournisseur: 'Tomate', HTVA: 60.00, TVA: 12.00, TTC: 72.00, typePayment: 'Pending' },
      { date: '2024-10-02', fournisseur: 'Tomate', HTVA: 135.00, TVA: 27.00, TTC: 162.00, typePayment: 'Pending' },
    ],
  },
  {
    name: 'Farine',
    status: 'Payed',
    image: `${process.env.PUBLIC_URL}assets/img/farine.png`,
    transactions: [
      { date: '2024-10-02', fournisseur: 'Farine', HTVA: 60.00, TVA: 12.00, TTC: 72.00, typePayment: 'Cash' },
      { date: '2024-10-02', fournisseur: 'Farine', HTVA: 135.00, TVA: 27.00, TTC: 162.00, typePayment: 'Bank Transfer' },
    ],
  },
  {
    name: 'Boisson',
    status: '30Dt Not Payed',
    image: `${process.env.PUBLIC_URL}/assets/img/boissons-gazeuses.jpg`,
    transactions: [
      { date: '2024-10-02', fournisseur: 'Boisson', HTVA: 60.00, TVA: 12.00, TTC: 72.00, typePayment: 'Pending' },
      { date: '2024-10-02', fournisseur: 'Boisson', HTVA: 135.00, TVA: 27.00, TTC: 162.00, typePayment: 'Pending' },
    ],
  },
  {
    name: 'Electricité',
    status: '3000Dt Not Payed',
    image: `${process.env.PUBLIC_URL}/assets/img/steg.png`,
    transactions: [
      { date: '2024-10-02', fournisseur: 'Electricité', HTVA: 60.00, TVA: 12.00, TTC: 72.00, typePayment: 'Pending' },
      { date: '2024-10-02', fournisseur: 'Electricité', HTVA: 135.00, TVA: 27.00, TTC: 162.00, typePayment: 'Pending' },
    ],
  },
];
