import './ExcelTable.css';
import { TbLockFilled } from "react-icons/tb";

const ExcelTableAcompte = () => {
  const data = [
    {
      date: '01/10/2023',

      acomptes: { tanel: 50, tarek: 100 },

    },
    {
      date: '02/10/2023',
    
      acomptes: { tanel: 70, tarek: 150 },
   
    },
    // Add more rows with similar data to match the table structure
  ];

  return (
    <div className="table-container">
      <table className="excel-table">
        <thead>
          <tr>
            <th rowSpan="2" className="header-cell">Date</th>
         
            <th colSpan="2" className="header-cell">ACOMPTES</th>
      
          </tr>
          <tr>
          
            <th className="sub-header-cell">TANEL</th>
            <th className="sub-header-cell">TAREK</th>
           
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className="data-row">
              <td>{row.date}</td>
           
              <td>{row.acomptes.tanel}</td>
              <td>{row.acomptes.tarek}</td>
            
            </tr>
          ))}
        </tbody>
      </table>
      <a className="see-more" href="#">

Voir Plus
<TbLockFilled />
</a>
    </div>
  );
};

export default ExcelTableAcompte;
