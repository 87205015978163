import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Sidebar from './Components/SideBar';

import './App.css'; // Import global styles for black background
import Dashboard from './Pages/Dashboard/Dashboard';
import Vault from './Pages/Vault/Vault';
import RH from './Pages/RH/RH';
import PartenairePage from './Pages/Partenaire/Partenaire';
import FournisseurPage from './Pages/Founisseurs/Fournisseurs';
import AllTransactionsVault from './Pages/AllTransactionsVault/AllTransactions';
import ProfilePage from './Pages/Profile/Profile';
import Analytics from './Pages/Analytics/Analytics';
import AllEncaissementsVault from './Pages/Encaissement/Encaissement';

import FoodCostPage from './Pages/FoodCost/FoodCost';
import IngredientsPage from './Pages/FoodCost/Ingredients';
import AllDecaissementVault from './Pages/Decaissement/Decaissement';
import FournisseurDetailsPage from './Pages/Founisseurs/FournisseurDeatils/FournisseurDeatils';

import PartnerDetailsPage from './Pages/Partenaire/PartenaireDetails/PartenaireDetailsPage';
import FullFournisseursPage from './Pages/Founisseurs/FullFournisseurPage';
import SignInScreen from './Pages/Login/SignIn';





function App() {
  return (
    <Router>
        
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();  // Hook to get the current location (route)

  // Check if the current route is '/'. If true, hide the sidebar.
  const shouldShowSidebar = location.pathname !== '/';

  return (
    <div className="app-container">
      {shouldShowSidebar && <Sidebar />}  {/* Conditionally render the sidebar */}
      <Routes>
         {/* Login page without Sidebar */}
        <Route path="/Dash" element={<Dashboard />} />
        <Route path="/vault" element={<Vault />} />
        <Route path="/" element={<SignInScreen />} />
        <Route path="/rh" element={<RH />} />
        <Route path="/partenaires" element={<PartenairePage />} />
        <Route path="/partners/:partnerName" element={<PartnerDetailsPage/>} />
        <Route path="/fournisseurs" element={<FournisseurPage />} />
        <Route path="/AllTransactionsVault" element={<AllTransactionsVault />} />
        <Route path="/Profile" element={<ProfilePage />} />
        <Route path="/Analytics" element={<Analytics />} />
        <Route path="/Enciasse" element={<AllEncaissementsVault />} />
        <Route path="/Ingredients" element={<IngredientsPage/>}/>
        <Route path="/fournisseurs/:name" element={<FournisseurDetailsPage />} /> {/* Dynamic route */}
        <Route path="/FoodCost" element={<FoodCostPage/>}/>
        <Route path="/Decaissement" element={<AllDecaissementVault/>}/>
        <Route path="/FullFournisseur" element={<FullFournisseursPage/>}/>
       
      </Routes>
    </div>
  );
}

export default App;
