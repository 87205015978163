import './ExcelTable.css';
import { TbLockFilled } from "react-icons/tb";

const ExcelTableDecaissement = () => {
  const data = [
    {
      date: '01/10/2023',
      
      decaissement: { especes: 183, service: 150, divers: 90 },
    
    },
    {
      date: '02/10/2023',
     
      decaissement: { especes: 200, service: 175, divers: 100 },
     
    },
    // Add more rows with similar data to match the table structure
  ];

  return (
    <div className="table-container">
      <table className="excel-table">
        <thead>
          <tr>
            <th rowSpan="2" className="header-cell">Date</th>
       
            <th colSpan="3" className="header-cell">DÉCAISSEMENT</th>
           
          </tr>
          <tr>
           
            <th className="sub-header-cell">Espèces</th>
            <th className="sub-header-cell">Service</th>
            <th className="sub-header-cell">Divers</th>
            
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className="data-row">
              <td>{row.date}</td>
             
              <td>{row.decaissement.especes}</td>
              <td>{row.decaissement.service}</td>
              <td>{row.decaissement.divers}</td>
            
            </tr>
          ))}
        </tbody>
      </table>
   
      <a className="see-more" href="/Decaissement">

        Voir Plus
        <i className="fas fa-arrow-right" aria-hidden="true" />
      </a>
    </div>
  );
};

export default ExcelTableDecaissement;
