import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import styles from './FoodCost.module.css';
import { initialIngredients } from './dummyData.jsx';
import ConstructionAlert from '../../Components/ConstructionAlert/ConstructionAlert';
const IngredientsPage = () => {
  const [ingredients, setIngredients] = useState(initialIngredients);
  const [newIngredientName, setNewIngredientName] = useState("");
  const [newIngredientCost, setNewIngredientCost] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIngredient, setEditingIngredient] = useState(null);

  // Handle adding a new ingredient
  const handleAddIngredient = () => {
    const newIngredient = {
      name: newIngredientName,
      cost: parseFloat(newIngredientCost),
      unit: 'Gram', // Default unit
    };

    setIngredients([...ingredients, newIngredient]);
    resetForm();
  };

  // Handle deleting an ingredient
  const handleDeleteIngredient = (name) => {
    setIngredients(ingredients.filter(ingredient => ingredient.name !== name));
  };

  // Handle editing an ingredient
  const handleEditIngredient = (ingredient) => {
    setNewIngredientName(ingredient.name);
    setNewIngredientCost(ingredient.cost);
    setEditingIngredient(ingredient);
    setIsModalOpen(true);
  };

  // Handle updating an ingredient
  const handleUpdateIngredient = () => {
    const updatedIngredients = ingredients.map(ingredient => 
      ingredient.name === editingIngredient.name
        ? { ...ingredient, name: newIngredientName, cost: parseFloat(newIngredientCost) }
        : ingredient
    );

    setIngredients(updatedIngredients);
    resetForm();
  };

  // Reset form fields
  const resetForm = () => {
    setNewIngredientName("");
    setNewIngredientCost("");
    setEditingIngredient(null);
    setIsModalOpen(false);
  };

  return (
    <div className={styles.foodCostPage}>
        <ConstructionAlert/>
      <div className={styles.header}>
        <h1>Liste des ingrédients</h1>
        <button onClick={() => setIsModalOpen(true)} className={styles.createIngredientButton}>
          <FontAwesomeIcon icon={faPlus} /> Add Ingredient
        </button>
      </div>

      <ul className={styles.ingredientsList}>
        {ingredients.map((ingredient) => (
          <li key={ingredient.name} className={styles.ingredientItem}>
            {ingredient.name} - TND{ingredient.cost.toFixed(2)} / {ingredient.unit}
            <div className={styles.actions}>
              <button onClick={() => handleEditIngredient(ingredient)} className={styles.editButton}>
                <FontAwesomeIcon icon={faEdit} /> Modifier
              </button>
              <button onClick={() => handleDeleteIngredient(ingredient.name)} className={styles.deleteButton}>
                <FontAwesomeIcon icon={faTrash} /> Supprimer
              </button>
            </div>
          </li>
        ))}
      </ul>

      {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h2>{editingIngredient ? "Edit Ingredient" : "Add New Ingredient"}</h2>
            <input
              type="text"
              placeholder="Ingredient Name"
              value={newIngredientName}
              onChange={(e) => setNewIngredientName(e.target.value)}
              className={styles.modalInput}
            />
            <input
              type="number"
              placeholder="Cost"
              value={newIngredientCost}
              onChange={(e) => setNewIngredientCost(e.target.value)}
              className={styles.modalInput}
            />
            {editingIngredient ? (
              <button onClick={handleUpdateIngredient} className={styles.modalButton}>
            Mettre à jour l'ingrédient
              </button>
            ) : (
              <button onClick={handleAddIngredient} className={styles.modalButton}>
           Ajouter un ingrédient
              </button>
            )}
            <button onClick={resetForm} className={styles.modalCloseButton}>
            Fermer
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default IngredientsPage;
