import React from 'react';
import styles from './Partenaire.module.css'; // Ensure styles are imported correctly
import '@fortawesome/fontawesome-free/css/all.min.css';
import PartnerTable from '../../Components/ExcelPartenaireTable/ExcelPartenaireTable';
import {partnersData} from './DummyDataPartner' 

import { Link } from 'react-router-dom'; // Import Link from React Router
import ConstructionAlert from '../../Components/ConstructionAlert/ConstructionAlert';
function PartenairePage() {
  return (
    <div className={styles.partenaire}>
      <ConstructionAlert/>
      {/* Top Section */}
      <div className={styles.greeting}>
        <h2>Partenaire!</h2>
        <p>Here's your overview of your partners!</p>
      </div>

      {/* Partner Table */}
      <PartnerTable data={partnersData} />

      {/* Partners Section */}
      <div className={styles.partnersSection}>
        <div className={styles.statsSection}>
          {partnersData.map((partner, index) => (
             <Link 
             to={`/partenaires`} 
             key={index} 
             className={styles.Card}
           >
          
              <img src={partner.image} alt={partner.name} className={styles.partnerImage} />
              <h3 style={{ color: 'black' , fontWeight: "bold", fontSize:"20px" }}>{partner.name}</h3>

              <p>Contribution: {partner.contribution}</p>
          
            </Link>
          ))}
        </div>

       
      </div>
    </div>
  );
}

export default PartenairePage;
