import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { fournisseursData } from './DummyDataFournisseur';
import styles from './FournisseurDeatils.module.css';
import { FaCalendarAlt, FaMoneyBillWave, FaInfoCircle, FaPlus, FaEdit, FaTrash, FaFileInvoice } from 'react-icons/fa';
import ConstructionAlert from '../../../Components/ConstructionAlert/ConstructionAlert';

function FournisseurDetailsPage() {
  const { name } = useParams();
  const fournisseur = fournisseursData.find((item) => item.name === name);

  const [transactions, setTransactions] = useState(fournisseur ? fournisseur.transactions : []);
  const [formData, setFormData] = useState({ date: '', numeroFacture: '', HTVA: '', TVA: '', TTC: '', typePayment: '' });
  const [editIndex, setEditIndex] = useState(null);

  if (!fournisseur) {
    return <div className={styles.notFound}>Fournisseur not found</div>;
  }

  // Handle input change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Add new transaction
  const addTransaction = () => {
    setTransactions([...transactions, formData]);
    setFormData({ date: '', numeroFacture: '', HTVA: '', TVA: '', TTC: '', typePayment: '' });
  };

  // Edit an existing transaction
  const editTransaction = (index) => {
    setFormData(transactions[index]);
    setEditIndex(index);
  };

  // Update transaction after editing
  const updateTransaction = () => {
    const updatedTransactions = transactions.map((transaction, index) =>
      index === editIndex ? formData : transaction
    );
    setTransactions(updatedTransactions);
    setFormData({ date: '', numeroFacture: '', HTVA: '', TVA: '', TTC: '', typePayment: '' });
    setEditIndex(null);
  };

  // Delete transaction
  const deleteTransaction = (index) => {
    const updatedTransactions = transactions.filter((_, i) => i !== index);
    setTransactions(updatedTransactions);
  };

  return (
    <div className={styles.detailsPage}>
      <ConstructionAlert/>
      <div className={styles.header}>
        <h2>Details for {fournisseur.name}</h2>
        <img src={fournisseur.image} alt={fournisseur.name} className={styles.image} />
      </div>

      <div className={styles.statusCard}>
        <p className={styles.status}>Status: {fournisseur.status}</p>
      </div>

      <div className={styles.transactionsSection}>
        <h3>Transactions</h3>
        <div className={styles.transactions}>
          {transactions.map((transaction, index) => (
            <div className={styles.transactionCard} key={index}>
              <p><FaCalendarAlt /> Date: {transaction.date}</p>
              <p><FaFileInvoice /> Facture: {transaction.numeroFacture}</p>
              <p><FaMoneyBillWave /> HTVA: {transaction.HTVA}</p>
              <p><FaInfoCircle /> TVA: {transaction.TVA}</p>
              <p><FaMoneyBillWave /> TTC: {transaction.TTC}</p>
              <p><FaInfoCircle /> Payment Type: {transaction.typePayment}</p>
              
              <div className={styles.actions}>
                <button onClick={() => editTransaction(index)}><FaEdit /> Edit</button>
                <button onClick={() => deleteTransaction(index)}><FaTrash /> Delete</button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.formSection}>
        <h3>{editIndex !== null ? "Edit Transaction" : "Add Transaction"}</h3>
        <form onSubmit={(e) => e.preventDefault()} className={styles.form}>
          <label>
            Date:
            <input type="text" name="date" value={formData.date} onChange={handleChange} />
          </label>
          <label>
            Numero de Facture:
            <input type="text" name="numeroFacture" value={formData.numeroFacture} onChange={handleChange} />
          </label>
          <label>
            HTVA:
            <input type="number" name="HTVA" value={formData.HTVA} onChange={handleChange} />
          </label>
          <label>
            TVA:
            <input type="number" name="TVA" value={formData.TVA} onChange={handleChange} />
          </label>
          <label>
            TTC:
            <input type="number" name="TTC" value={formData.TTC} onChange={handleChange} />
          </label>
          <label>
            Payment Type:
            <input type="text" name="typePayment" value={formData.typePayment} onChange={handleChange} />
          </label>
          <button type="button" onClick={editIndex !== null ? updateTransaction : addTransaction}>
            {editIndex !== null ? "Update Transaction" : "Add Transaction"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default FournisseurDetailsPage;
