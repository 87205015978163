import React, { useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styles from './RH.module.css';
import { FaPlus, FaTrash, FaEdit } from 'react-icons/fa'; // Icons for buttons

import ConstructionAlert from '../../Components/ConstructionAlert/ConstructionAlert';
// Localization settings
const locales = {
  'en-US': require('date-fns/locale/en-US'),
};

const formatDate = (date) => format(date, 'MMMM d, yyyy');
const formatDay = (date) => format(date, 'EEEE');
const formatWeekday = (date) => format(date, 'EEEE');
const formatMonthHeader = (date) => format(date, 'MMMM yyyy');

const localizer = dateFnsLocalizer({
  format: formatDate,
  formatDay,
  formatWeekday,
  formatMonthHeader,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const RH = () => {
  const [workers, setWorkers] = useState([
    {
      id: 1,
      name: 'Yassine Baccouche',
      image: `${process.env.PUBLIC_URL}/assets/img/User.jpg`,
      cin: '12345678', 
      phone: '+216 98 765 432', 
      post: 'Engineer', 
      salary: 3000,
      present: true,
      workingHours: [{ start: new Date(2024, 9, 10, 9, 0), end: new Date(2024, 9, 10, 17, 0) }]
    },
    {
      id: 2,
      name: 'Amine Doje',
      image: `${process.env.PUBLIC_URL}/assets/img/User.jpg`,
      cin: '87654321',
      phone: '+216 99 123 456',
      post: 'Technician',
      salary: 2500,
      present: false,
      workingHours: [{ start: new Date(2024, 9, 10, 10, 0), end: new Date(2024, 9, 10, 18, 0) }]
    },
    {
      id: 3,
      name: 'Jane Smith',
      image: `${process.env.PUBLIC_URL}/assets/img/User.jpg`,
      cin: '11223344',
      phone: '+216 97 654 321',
      post: 'Manager',
      salary: 4000,
      present: true,
      workingHours: [{ start: new Date(2024, 9, 10, 8, 0), end: new Date(2024, 9, 10, 16, 0) }]
    },
  ]);
  
  const [modalOpen, setModalOpen] = useState(false);
  const [workerModalOpen, setWorkerModalOpen] = useState(false);
  const [currentWorker, setCurrentWorker] = useState(null);
  const [newWorkingHour, setNewWorkingHour] = useState({ start: new Date(), end: new Date() });
  const [newWorker, setNewWorker] = useState({
    name: '', 
    image: '', 
    cin: '', 
    phone: '', 
    post: '', 
    salary: '', 
    present: false, 
    workingHours: []
  });

  const events = workers.flatMap(worker =>
    worker.workingHours.map(hour => ({
      id: worker.id,
      title: worker.name,
      start: hour.start,
      end: hour.end,
    }))
  );

  const eventStyleGetter = (event) => {
    const style = {
      backgroundColor: '#4a90e2',
      borderRadius: '5px',
      opacity: 0.8,
      color: 'white',
      border: 'none',
      display: 'block',
    };
    return { style };
  };

  const openModal = (worker) => {
    setCurrentWorker(worker);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentWorker(null);
    setNewWorkingHour({ start: new Date(), end: new Date() });
  };

  const handleAddHour = () => {
    if (currentWorker) {
      const updatedWorkers = workers.map(worker => {
        if (worker.id === currentWorker.id) {
          return {
            ...worker,
            workingHours: [...worker.workingHours, newWorkingHour],
          };
        }
        return worker;
      });
      setWorkers(updatedWorkers);
    }
    handleCloseModal();
  };

  const handleDeleteHour = (workerId, hourIndex) => {
    const updatedWorkers = workers.map(worker => {
      if (worker.id === workerId) {
        const updatedHours = worker.workingHours.filter((_, index) => index !== hourIndex);
        return { ...worker, workingHours: updatedHours };
      }
      return worker;
    });
    setWorkers(updatedWorkers);
  };

  const openWorkerModal = () => {
    setWorkerModalOpen(true);
  };

  const handleCloseWorkerModal = () => {
    setWorkerModalOpen(false);
    setNewWorker({ name: '', image: '', cin: '', phone: '', post: '', salary: '', present: false, workingHours: [] });
  };

  const handleAddWorker = () => {
    if (newWorker.name && newWorker.image) {
      setWorkers([...workers, { ...newWorker, id: workers.length + 1 }]);
      handleCloseWorkerModal();
    }
  };

  const handleDeleteWorker = (workerId) => {
    const updatedWorkers = workers.filter(worker => worker.id !== workerId);
    setWorkers(updatedWorkers);
  };

  return (
    <div className={styles.rhContainer}>
        <ConstructionAlert />
      <h1>Heures des travailleurs</h1>
      <button className={styles.addButton} onClick={openWorkerModal}>
        <FaPlus /> Ajouter un travailleur
      </button>
      <div className={styles.calendar}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          eventPropGetter={eventStyleGetter}
          views={['month', 'week', 'day']}
          defaultView='month'
        />
      </div>

      <ul className={styles.workerList}>
  {workers.map(worker => (
    <li key={worker.id} className={styles.workerItem}>
      <img src={worker.image} alt={worker.name} className={styles.workerImage} />
      <div className={styles.workerInfo}>
        <strong className={styles.workerName}>{worker.name}</strong>
        <div className={styles.workerDetails}>
          <p><strong>CIN:</strong> {worker.cin}</p>
          <p><strong>Téléphone:</strong> {worker.phone}</p>
          <p><strong>Poste:</strong> {worker.post}</p>
          <p><strong>Salaire:</strong> {worker.salary} TND</p>
          <p><strong>Présent:</strong> 
            {worker.present ? <span className={styles.presentStatus}>Yes</span> : <span className={styles.absentStatus}>No</span>}
          </p>
        </div>
      </div>
      <div>
        <button className={styles.editButton} onClick={() => openModal(worker)}>
          <FaEdit /> Ajouter une heure
        </button>
        <button className={styles.deleteButton} onClick={() => handleDeleteWorker(worker.id)}>
          <FaTrash /> Supprimer
        </button>
      </div>
      <ul className={styles.workingHours}>
        {worker.workingHours.map((hour, index) => (
          <li key={index} className={styles.hourItem}>
            {format(hour.start, 'hh:mm a')} - {format(hour.end, 'hh:mm a')}
            <button className={styles.deleteHourButton} onClick={() => handleDeleteHour(worker.id, index)}>
              <FaTrash /> Supprimer
            </button>
          </li>
        ))}
      </ul>
    </li>
  ))}
</ul>


      {modalOpen && (
        <div className={styles.modal}>
          <h2>Ajouter une heure de travail pour {currentWorker?.name}</h2>
          <label>
          Commencer:
            <input
              type="datetime-local"
              value={format(newWorkingHour.start, 'yyyy-MM-ddTHH:mm')}
              onChange={(e) => setNewWorkingHour({ ...newWorkingHour, start: new Date(e.target.value) })}
            />
          </label>
          <label>
          Fin:
            <input
              type="datetime-local"
              value={format(newWorkingHour.end, 'yyyy-MM-ddTHH:mm')}
              onChange={(e) => setNewWorkingHour({ ...newWorkingHour, end: new Date(e.target.value) })}
            />
          </label>
          <button className={styles.modalButton} onClick={handleAddHour}>Ajouter</button>
          <button className={styles.modalButton} onClick={handleCloseModal}>Annuler</button>
        </div>
      )}

{workerModalOpen && (
  <div className={styles.modal}>
    <h2>Ajouter un nouveau travailleur</h2>
    
    <label>
      Nom:
      <input
        type="text"
        value={newWorker.name}
        onChange={(e) => setNewWorker({ ...newWorker, name: e.target.value })}
      />
    </label>
    
    <label>
      Image URL:
      <input
        type="text"
        value={newWorker.image}
        onChange={(e) => setNewWorker({ ...newWorker, image: e.target.value })}
      />
    </label>
    
    <label>
      CIN:
      <input
        type="text"
        value={newWorker.cin}
        onChange={(e) => setNewWorker({ ...newWorker, cin: e.target.value })}
      />
    </label>

    <label>
    Téléphone:
      <input
        type="text"
        value={newWorker.phone}
        onChange={(e) => setNewWorker({ ...newWorker, phone: e.target.value })}
      />
    </label>

    <label>
      Poste:
      <input
        type="text"
        value={newWorker.post}
        onChange={(e) => setNewWorker({ ...newWorker, post: e.target.value })}
      />
    </label>

    <label>
    Salaire:
      <input
        type="number"
        value={newWorker.salary}
        onChange={(e) => setNewWorker({ ...newWorker, salary: Number(e.target.value) })}
      />
    </label>

    <label>
      Présent:
      <input
        type="checkbox"
        checked={newWorker.present}
        onChange={(e) => setNewWorker({ ...newWorker, present: e.target.checked })}
      />
      Oui
    </label>

    <div className={styles.modalActions}>
      <button className={styles.modalButton} onClick={handleAddWorker}>Add</button>
      <button className={styles.modalButton} onClick={handleCloseWorkerModal}>Cancel</button>
    </div>
  </div>
)}

    
    </div>
  );
};

export default RH;
