import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import styles from './Fournisseur.module.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { fournisseursData } from './DummyDataFounisseur';
import FullExcelFournisseur from '../../Components/ExcelFournisseurtable/FullExcelFournisseur';
import ConstructionAlert from '../../Components/ConstructionAlert/ConstructionAlert';

function FullFournisseursPage() {
  return (
    <div className={styles.FournisseursPage}>
           <ConstructionAlert/>
      <div className={styles.greeting}>
        <h2>Transactions Fournisseurs!</h2>
      </div>
      <FullExcelFournisseur data={fournisseursData} />

      
    </div>
  );
}

export default FullFournisseursPage;
