import React from 'react';
import './ExcelTable.css';

const ExcelFournisseur = ({ data }) => {
  return (
    <div className="table-container">
      <table className="excel-table">
        <thead>
          <tr>
            <th className="header-cell">Fournisseur</th>
            <th className="header-cell">Date</th>
            <th className="header-cell">Montant HTVA</th>
            <th className="header-cell">TVA</th>
            <th className="header-cell">TTC</th>
            <th className="header-cell">Type de Paiement</th>
          </tr>
        </thead>
        <tbody>
          {data.map((fournisseur, fournisseurIndex) => {
            const lastTransaction = fournisseur.transactions[fournisseur.transactions.length - 1];
            return (
              <tr key={fournisseurIndex} className="data-row">
                <td>{fournisseur.name}</td>
                <td>{lastTransaction.date}</td>
                <td>{lastTransaction.HTVA.toFixed(2)}</td>
                <td>{lastTransaction.TVA.toFixed(2)}</td>
                <td>{lastTransaction.TTC.toFixed(2)}</td>
                <td>{lastTransaction.typePayment}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <a className="see-more" href="/FullFournisseur">
        Voir Plus
        <i className="fas fa-arrow-right" aria-hidden="true" />
      </a>
    </div>
  );
};

export default ExcelFournisseur;
