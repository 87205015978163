import React, { useState } from 'react';
import styles from './ProfilePage.module.css';
import { FaSave, FaEdit, FaSignOutAlt } from 'react-icons/fa';

import ConstructionAlert from '../../Components/ConstructionAlert/ConstructionAlert';
const ProfilePage = () => {
  // Sample user data
  const [user, setUser] = useState({
    name: 'StartupTunisie',
    email: 'StartUpTunisia@support.com',
    phone: '+123 456 7890',
    address: 'Lac,Tunis',
    role: 'Owner',
    department: '.......',
    avatar: `../assets/img/avatar.png`, // Placeholder image
  });

  // Local state to hold form changes
  const [editMode, setEditMode] = useState(false);
  const [updatedUser, setUpdatedUser] = useState(user);

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUpdatedUser((prevState) => ({
          ...prevState,
          avatar: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    setUser(updatedUser);
    setEditMode(false);
    
    // Display success message
    const successMessage = document.createElement('div');
    successMessage.innerText = 'Profile updated successfully!';
    successMessage.className = styles.successMessage;
    document.body.appendChild(successMessage);
    
    // Fade out the message after 3 seconds
    setTimeout(() => {
      successMessage.style.opacity = 0;
      setTimeout(() => {
        document.body.removeChild(successMessage);
      }, 300);
    }, 3000);
  };
  

  return (
    
    <div className={styles.profileContainer}>
        <ConstructionAlert />
      
      <div className={styles.profileHeader}>
        <img src={updatedUser.avatar} alt="User Avatar" className={styles.avatar} />
        {editMode ? (
          <input type="file" accept="image/*" onChange={handleImageUpload} className={styles.fileInput} />
        ) : null}

        <h1 className={styles.userName}>
          {editMode ? (
            <input
              type="text"
              name="name"
              value={updatedUser.name}
              onChange={handleChange}
              className={styles.inputField}
            />
          ) : (
            user.name
          )}
        </h1>

        <p className={styles.userEmail}>
          {editMode ? (
            <input
              type="email"
              name="email"
              value={updatedUser.email}
              onChange={handleChange}
              className={styles.inputField}
            />
          ) : (
            user.email
          )}
        </p>
      </div>

      <div className={styles.profileDetails}>
        <h2>Détails du profil</h2>

        <div className={styles.detailItem}>
          <strong>Téléphone:</strong>
          {editMode ? (
            <input
              type="text"
              name="phone"
              value={updatedUser.phone}
              onChange={handleChange}
              className={styles.inputField}
            />
          ) : (
            <span>{user.phone}</span>
          )}
        </div>

        <div className={styles.detailItem}>
          <strong>Addresse:</strong>
          {editMode ? (
            <input
              type="text"
              name="address"
              value={updatedUser.address}
              onChange={handleChange}
              className={styles.inputField}
            />
          ) : (
            <span>{user.address}</span>
          )}
        </div>

        <div className={styles.detailItem}>
          <strong>Role:</strong>
          {editMode ? (
            <input
              type="text"
              name="role"
              value={updatedUser.role}
              onChange={handleChange}
              className={styles.inputField}
            />
          ) : (
            <span>{user.role}</span>
          )}
        </div>

        <div className={styles.detailItem}>
          <strong>Department:</strong>
          {editMode ? (
            <input
              type="text"
              name="department"
              value={updatedUser.department}
              onChange={handleChange}
              className={styles.inputField}
            />
          ) : (
            <span>{user.department}</span>
          )}
        </div>
      </div>

      <div className={styles.profileActions}>
        {editMode ? (
          <>
           <button className={styles.saveButton} onClick={handleSave}>
           <FaSave style={{ marginRight: '8px' }} />
           Enregistrer le profil
          </button>
          <button className={styles.editButton} onClick={() => setEditMode(true)}>
         <FaEdit style={{ marginRight: '8px' }} />
         Modifier le profil
</button>
          </>
        ) : (
          <button className={styles.editButton} onClick={() => setEditMode(true)}>
            Modifier le profil
          </button>
        )}
        <button className={styles.logoutButton}>Déconnexion</button>
      </div>
    </div>
  );
};

export default ProfilePage;
