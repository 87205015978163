import React from 'react';
import ConstructionAlert from '../../Components/ConstructionAlert/ConstructionAlert';
import {
  BarChart, Bar,
  LineChart, Line,
  PieChart, Pie, Cell,
  AreaChart, Area,
  XAxis, YAxis,
  CartesianGrid, Tooltip, Legend,
  ResponsiveContainer
} from 'recharts';
import styles from './Analytics.module.css';

const data = [
  { name: 'January', uv: 4000, pv: 2400, amt: 2400 },
  { name: 'February', uv: 3000, pv: 1398, amt: 2210 },
  { name: 'March', uv: 2000, pv: 9800, amt: 2290 },
  { name: 'April', uv: 2780, pv: 3908, amt: 2000 },
  { name: 'May', uv: 1890, pv: 4800, amt: 2181 },
  { name: 'June', uv: 2390, pv: 3800, amt: 2500 },
  { name: 'July', uv: 3490, pv: 4300, amt: 2100 },
];

// Data for pie chart
const pieData = [
  { name: 'Online Sales', value: 400 },
  { name: 'In-Store Sales', value: 300 },
  { name: 'Wholesale', value: 300 },
];

function Analytics() {
  return (
    <div className={styles.Analytics}>
        <ConstructionAlert/>
      {/* Top Section */}
      <div className={styles.greeting}>
        <h1>Analytique</h1>
       <br></br>
      </div>

      {/* Sales Overview Section */}
      <div className={styles.salesSection}>
        <div className={styles.salesOverview}>
          <h2>Aperçu des ventes</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pv" fill="#8884d8" />
              <Bar dataKey="uv" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Profit Trend Chart */}
        <div className={styles.schedule}>
          <h2>Tendance des bénéfices</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="pv" stroke="#8884d8" />
              <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Unique Charts Section */}
      <div className={styles.uniqueCharts}>
        {/* Pie Chart */}
        <div className={styles.pieChart}>
          <h2>Répartition des ventes</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie data={pieData} cx="50%" cy="50%" outerRadius={80} fill="#8884d8" label>
                {pieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={['#0088FE', '#00C49F', '#FFBB28'][index % 3]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Area Chart for Revenue Over Time */}
        <div className={styles.areaChart}>
          <h2>Revenus au fil du temps</h2>
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={0.3} fill="#8884d8" />
            </AreaChart>
          </ResponsiveContainer>
        </div>

        {/* New Chart: Stacked Bar Chart */}
        <div className={styles.stackedBarChart}>
          <h2>Revenu mensuel cumulé</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="uv" stackId="a" fill="#8884d8" />
              <Bar dataKey="pv" stackId="a" fill="#82ca9d" />
              <Bar dataKey="amt" stackId="a" fill="#ffc658" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* New Chart: Line Chart for Growth Rate */}
        <div className={styles.growthRateChart}>
          <h2>Taux de croissance au fil des mois</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="amt" stroke="#ff7300" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

export default Analytics;
