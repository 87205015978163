import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEdit, faTrash, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useRef, useEffect } from 'react';
import styles from './FoodCost.module.css';
import { Link } from 'react-router-dom';
import { initialIngredients, initialProducts } from './dummyData.jsx';
import ConstructionAlert from '../../Components/ConstructionAlert/ConstructionAlert';
const FoodCostPage = () => {
  const [ingredients] = useState(initialIngredients);
  const [products, setProducts] = useState(initialProducts);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newProductName, setNewProductName] = useState("");
  const [selectedIngredients, setSelectedIngredients] = useState({});
  const [editingProduct, setEditingProduct] = useState(null);
  const [productQuantities, setProductQuantities] = useState({});
  const [totalCost, setTotalCost] = useState(0);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const calculateProductCost = (product) => {
    return product.ingredients.reduce((total, ing) => {
      const ingredient = ingredients.find((ingredient) => ingredient.name === ing.name);
      if (ingredient) {
        let costPerUnit = ingredient.cost;
        if (ingredient.unit === 'Gram') costPerUnit /= 1000; // Convert grams to kilograms
        return total + costPerUnit * ing.quantity;
      }
      return total;
    }, 0).toFixed(2);
  };

  const calculateTotalCost = () => {
    const total = products.reduce((acc, product) => {
      const quantity = productQuantities[product.id] || 0;
      const productCost = calculateProductCost(product);
      return acc + (quantity * productCost);
    }, 0);
    setTotalCost(total.toFixed(2));
  };

  useEffect(() => {
    calculateTotalCost();
  }, [products, productQuantities]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const scrollRef = useRef(null);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    if (isModalOpen) {
      resetModal();
    }
  };

  const resetModal = () => {
    setNewProductName("");
    setSelectedIngredients({});
    setEditingProduct(null);
    setFeedbackMessage(""); // Reset feedback message
  };

  const handleAddOrUpdateProduct = () => {
    const newProductIngredients = Object.entries(selectedIngredients)
      .filter(([_, quantity]) => quantity > 0)
      .map(([name, quantity]) => ({ name, quantity }));

    if (editingProduct) {
      const updatedProducts = products.map((product) => {
        if (product.id === editingProduct.id) {
          return { ...product, name: newProductName, ingredients: newProductIngredients };
        }
        return product;
      });
      setProducts(updatedProducts);
      setFeedbackMessage("Product updated successfully!");
    } else {
      const newProduct = {
        id: products.length + 1,
        name: newProductName,
        image: '/assets/img/Products/default_product.jpg',
        ingredients: newProductIngredients,
      };
      setProducts([...products, newProduct]);
      setFeedbackMessage("Product added successfully!");
    }
    
    setIsModalOpen(false);
    resetModal();
  };

  const handleEditProduct = (product) => {
    setNewProductName(product.name);
    const ingredientQuantities = {};
    product.ingredients.forEach((ing) => {
      ingredientQuantities[ing.name] = ing.quantity;
    });
    setSelectedIngredients(ingredientQuantities);
    setEditingProduct(product);
    toggleModal();
  };

  const handleDeleteProduct = (id) => {
    const confirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ce produit ?");
    if (confirmed) {
      setProducts(products.filter(product => product.id !== id));
      setFeedbackMessage("Produit supprimé avec succès !");
    }
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchQuery)
  );

  const handleIngredientChange = (ingredientName) => {
    setSelectedIngredients(prev => ({
      ...prev,
      [ingredientName]: prev[ingredientName] ? 0 : 1
    }));
  };

  const handleQuantityChange = (ingredientName, quantity) => {
    setSelectedIngredients(prev => ({
      ...prev,
      [ingredientName]: quantity ? Math.max(0, Number(quantity)) : 0 // Prevent negative quantities
    }));
  };

  const handleProductQuantityChange = (productId, delta) => {
    setProductQuantities(prev => {
      const newQuantity = (prev[productId] || 0) + delta;
      return { ...prev, [productId]: Math.max(newQuantity, 0) }; // Prevent negative quantities
    });
  };

  return (
    <div className={styles.foodCostPage}>
        <ConstructionAlert/>
      <div className={styles.header}>
        <h1>Food Cost Calculateur</h1>
        <div className={styles.totalCostCard}>
          <h2>Coût total: TND {totalCost}</h2>
        </div>
        <div className={styles.buttonss}>
          <Link to="/ingredients" className={styles.ingredientsButton}>
          Ingredients
          </Link>
          <button onClick={toggleModal} className={styles.createProductButton}>
          Créer un produit
          </button>
        </div>
      </div>

      <div className={styles.searchBar}>
        <input
          type="text"
          placeholder="Search products..."
          value={searchQuery}
          onChange={handleSearchChange}
          className={styles.searchInput}
        />
        <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
      </div>

      {feedbackMessage && <div className={styles.feedbackMessage}>{feedbackMessage}</div>}

      {filteredProducts.length === 0 ? (
        <p>Aucun produit trouvé.</p>
      ) : (
        <div className={styles.productList}>
          {filteredProducts.map((product) => {
            const productCost = calculateProductCost(product);
            return (
              <div key={product.id} className={styles.productCard}>
                <img src={product.image} alt={product.name} className={styles.productImage} />
                <h3 className={styles.productName}>{product.name}</h3>
                <ul className={styles.ingredient}>
                  {product.ingredients.map((ing) => {
                    const ingredient = ingredients.find((i) => i.name === ing.name);
                    const cost = ingredient ? (ingredient.cost * ing.quantity).toFixed(2) : '0.00';
                    return (
                      <li className={styles.ingredientsList} key={ing.name}>
                        {ing.name} - {ing.quantity} kg (TND {cost})
                      </li>
                    );
                  })}
                </ul>
                <p className={styles.productCost}>Coût total : TND {productCost}</p>
                <div className={styles.productActions}>
                  <div className={styles.quantitySelector}>
                    <button 
                      onClick={() => handleProductQuantityChange(product.id, -1)} 
                      className={styles.iconButton}
                    >
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </button>
                    <span>{productQuantities[product.id] || 0}</span>
                    <button 
                      onClick={() => handleProductQuantityChange(product.id, 1)} 
                      className={styles.iconButton}
                    >
                      <FontAwesomeIcon icon={faPlusCircle} />
                    </button>
                  </div>
                  <button onClick={() => handleEditProduct(product)} className={styles.editButton}>
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button onClick={() => handleDeleteProduct(product.id)} className={styles.deleteButton}>
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div ref={scrollRef} className={styles.scrollTargetSection}>
        {isModalOpen && (
          <div className={styles.modal}>
            <div className={styles.modalContent}>
              <h2>{editingProduct ? "Edit Product" : "Add New Product"}</h2>
              <input
                type="text"
                placeholder="Product Name"
                value={newProductName}
                onChange={(e) => setNewProductName(e.target.value)}
                className={styles.modalInput}
                required
              />

              <h3>Sélectionnez les ingrédients</h3>
              {ingredients.map((ingredient) => (
                <div key={ingredient.name} className={styles.ingredientItem}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedIngredients[ingredient.name] > 0}
                      onChange={() => handleIngredientChange(ingredient.name)}
                    />
                    {ingredient.name} (TND{ingredient.cost.toFixed(2)}/{ingredient.unit})
                  </label>
                  {selectedIngredients[ingredient.name] > 0 && (
                    <input
                      type="number"
                      min="0"
                      value={selectedIngredients[ingredient.name] || ""}
                      onChange={(e) => handleQuantityChange(ingredient.name, e.target.value)}
                      className={styles.quantityInput}
                    />
                  )}
                </div>
              ))}
              <div className={styles.modalButtons}>
                <button onClick={handleAddOrUpdateProduct} className={styles.modalSaveButton}>
                Sauvegarder
                </button>
                <button onClick={toggleModal} className={styles.modalCancelButton}>
                  Annuler
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FoodCostPage;
