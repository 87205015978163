import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const SignInScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isAuth, setIsAuth] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (email === "startuptunisia" && password === "OptimiziTech") {
      setIsAuth(true);
      navigate("/Dash"); // Redirect to dashboard
    } else {
      setIsAuth(false);
      setError("Invalid username or password");
    }
  };

  return (
    <div style={{ width: "100vw", height: "100vh", display: "flex", flexDirection: "column", backgroundImage: "linear-gradient(to bottom right, #002147, #004d91)", backgroundRepeat: "no-repeat", backgroundSize: "cover", fontFamily: "'Poppins', sans-serif", color: "#fff" }}>
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-dark" style={{ backgroundColor: "#001f3f", padding: "15px 50px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}>
        <img src={`${process.env.PUBLIC_URL}/assets/img/LogoOptimizi.png`} alt="Logo" height="90" />
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto" style={{ gap: "15px" }}>
            <li className="nav-item"><a className="nav-link active" href="#">Dashboard</a></li>
            <li className="nav-item"><a className="nav-link" href="#">Features</a></li>
            <li className="nav-item"><a className="nav-link" href="#">Cases</a></li>
            <li className="nav-item"><a className="nav-link" href="#">Pricing</a></li>
            <li className="nav-item"><a className="nav-link" href="#">About Us</a></li>
          </ul>
        </div>
      </nav>

      {/* Login Section */}
      <div className="login-container" style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", textAlign: "center", padding: "15px" }}>
        <h1 style={{ fontSize: "36px", color: "#ffffff", marginBottom: "20px", fontWeight: "600" }}>Client <span style={{ color: "#00bcd4" }}>Portal</span></h1>
        <div className="card" style={{ backgroundColor: "#1c2b4a", borderRadius: "15px", padding: "30px", width: "100%", maxWidth: "60vh", border: "none", boxShadow: "0 8px 24px rgba(0, 0, 0, 0.2)" }}>
          <div className="card-body" style={{ padding: "20px" }}>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label" style={{ color: "#ffffff", fontWeight: "500" }}>Email or Username</label>
                <input 
                  type="text" 
                  className="form-control" 
                  id="email" 
                  name="email" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                  required 
                  style={{ backgroundColor: "#002147", border: "1px solid #00bcd4", borderRadius: "25px", color: "#fff", padding: "15px", fontSize: "16px" }} 
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label" style={{ color: "#ffffff", fontWeight: "500" }}>Password</label>
                <input 
                  type="password" 
                  className="form-control" 
                  id="password" 
                  name="password" 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)} 
                  required 
                  style={{ backgroundColor: "#002147", border: "1px solid #00bcd4", borderRadius: "25px", color: "#fff", padding: "15px", fontSize: "16px" }} 
                />
                <div className="text-end mt-2">
                  <a href="#" className="forgot-password" style={{ color: "#00bcd4", fontSize: "14px", textDecoration: "none" }}>Forgot your password?</a>
                </div>
              </div>
              {error && <div className="alert alert-danger" role="alert">{error}</div>}
              <button 
                type="submit" 
                className="btn btn-primary" 
                style={{ backgroundImage: "linear-gradient(to right, #00bcd4, #0097a7)", border: "none", color: "#fff", fontWeight: "600", fontSize: "16px", padding: "12px 25px", borderRadius: "25px", width: "100%", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", transition: "background 0.3s ease" }}
              >
                Login
              </button>
            </form>
          </div>
          <div className="card-footer text-center mt-4">
            <p style={{ color: "#fff" }}>Don't have an account?</p>
            <a href="mailto:OptimiziTech@gmail.com" className="custom-link" style={{
              color: "#00bcd4", 
              border: "2px solid #00bcd4", 
              background: "transparent", 
              fontWeight: "600", 
              borderRadius: "25px", 
              padding: "10px 20px", 
              textAlign: "center", 
              width: "100%", 
              display: "inline-block", 
              transition: "background 0.3s ease"
            }}>
              Register Now
            </a>
          </div>
        </div>
        <button 
          className="btn btn-info mt-4" 
          style={{ borderRadius: "50%", width: "50px", height: "50px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}
          onClick={() => setShowModal(true)}
        >
          <i className="bi bi-info-circle" style={{ fontSize: "24px" }}></i>
        </button>
      </div>

      {/* Authentication Status */}
      {isAuth && <div style={{ position: "fixed", bottom: "10px", right: "10px", backgroundColor: "#00bcd4", color: "#fff", padding: "10px 20px", borderRadius: "5px" }}>Authenticated</div>}

      {/* Modal */}
      {showModal && (
        <div className="modal show fade" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content" style={{ backgroundColor: "#1c2b4a", color: "#fff" }}>
              <div className="modal-header">
                <h5 className="modal-title">Demo Credentials</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowModal(false)}></button>
              </div>
              <div className="modal-body">
                <p><strong>Username:</strong> startuptunisia</p>
                <p><strong>Password:</strong> OptimiziTech</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={() => setShowModal(false)}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignInScreen;
