import React, { useState } from 'react';
import styles from './Encaissement.module.css';
import ExcelTable from '../../Components/Encaissement';
import Modal from '../../Components/PopUp/PopUp'; // Import the modal component
import ConstructionAlert from '../../Components/ConstructionAlert/ConstructionAlert';

const TRANSACTIONS_PER_PAGE = 30;

function AllEncaissementsVault() {
  const generateDummyData = () => {
    const data = [];
    const today = new Date();

    for (let i = 0; i < 90; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);

      data.push({
        date: date.toLocaleDateString('fr-FR'),
        encaissement: { especes: Math.floor(Math.random() * 1000), cheques: Math.floor(Math.random() * 500), tpeBat: Math.floor(Math.random() * 300), sodexo: Math.floor(Math.random() * 400), jumia: Math.floor(Math.random() * 600) },
        decaissement: { especes: Math.floor(Math.random() * 300), service: Math.floor(Math.random() * 200), divers: Math.floor(Math.random() * 150) },
        acomptes: { tanel: Math.floor(Math.random() * 100), tarek: Math.floor(Math.random() * 200) },
        recap: { total: Math.floor(Math.random() * 500), rapport: Math.floor(Math.random() * 100), ecart: (Math.random() > 0.5 ? '+' : '-') + (Math.random() * 1).toFixed(2) },
      });
    }

    return data;
  };

  const dummyData = generateDummyData();
  const [currentPage, setCurrentPage] = useState(0);
  const [transactions, setTransactions] = useState(dummyData);
  const [isModalOpen, setModalOpen] = useState(false);

  const startIndex = currentPage * TRANSACTIONS_PER_PAGE;
  const endIndex = startIndex + TRANSACTIONS_PER_PAGE;
  const currentTransactions = transactions.slice(startIndex, endIndex);
  const totalPages = Math.ceil(transactions.length / TRANSACTIONS_PER_PAGE);

  const goToPage = (page) => {
    if (page < 0 || page >= totalPages) return;
    setCurrentPage(page);
  };

  const handleAddEncaissement = (newEncaissement) => {
    setTransactions([newEncaissement, ...transactions]); // Add new encaissement to the top of the list
  };

  return (
    <div className={styles.Encaissement}>
        <ConstructionAlert/>
      <h1>Caisse</h1>

      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.header}>
            <h2>Vault</h2>
            {/* Button to open the modal */}
            <button onClick={() => setModalOpen(true)} className={styles.addButton}>+ Ajouter Encaissement</button>
          </div>

          <ExcelTable data={currentTransactions} />

          {/* Pagination Controls */}
          <div className={styles.pagination}>
            <button onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 0}>
              Précédent
            </button>
            <span>{`${currentPage + 1} / ${totalPages}`}</span>
            <button onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages - 1}>
              Suivant
            </button>
          </div>
        </div>
      </div>

      {/* Modal for adding encaissement */}
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} onSubmit={handleAddEncaissement} />
    </div>
  );
}

export default AllEncaissementsVault;
