import React, { useState } from 'react';
import styles from './AllTransactions.module.css';
import ExcelTable from '../../Components/Excel';
import ConstructionAlert from '../../Components/ConstructionAlert/ConstructionAlert';
const TRANSACTIONS_PER_PAGE = 30; // Number of transactions to display per page

function AllTransactionsVault() {
  const generateDummyData = () => {
    const data = [];
    const today = new Date();

    for (let i = 0; i < 90; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);

      data.push({
        date: date.toLocaleDateString('fr-FR'),
        encaissement: { 
          especes: Math.floor(Math.random() * 1000), 
          cheques: Math.floor(Math.random() * 500), 
          tpeBat: Math.floor(Math.random() * 300), 
          sodexo: Math.floor(Math.random() * 400), 
          jumia: Math.floor(Math.random() * 600) 
        },
        decaissement: { 
          especes: Math.floor(Math.random() * 300), 
          service: Math.floor(Math.random() * 200), 
          divers: Math.floor(Math.random() * 150) 
        },
        acomptes: { 
          tanel: Math.floor(Math.random() * 100), 
          tarek: Math.floor(Math.random() * 200) 
        },
        recap: { 
          total: Math.floor(Math.random() * 500), 
          rapport: Math.floor(Math.random() * 100), 
          ecart: (Math.random() > 0.5 ? '+' : '-') + (Math.random() * 1).toFixed(2)
        },
      });
    }

    return data;
  };

  const dummyData = generateDummyData();
  const [currentPage, setCurrentPage] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const filteredData = dummyData.filter(transaction => {
    const transactionDate = new Date(transaction.date.split('/').reverse().join('-'));
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;
    
    return (!start || transactionDate >= start) && (!end || transactionDate <= end);
  });

  const startIndex = currentPage * TRANSACTIONS_PER_PAGE;
  const endIndex = startIndex + TRANSACTIONS_PER_PAGE;
  const currentTransactions = filteredData.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredData.length / TRANSACTIONS_PER_PAGE);

  const goToPage = (page) => {
    if (page < 0 || page >= totalPages) return;
    setCurrentPage(page);
  };

  return (
    <div className={styles.dashboard}>
        <ConstructionAlert/>
      <h1>Caisse</h1>

      <div className={styles.container}>
        <div className={styles.section}>
          <h2>Vault</h2>

          {/* Date Range Filter */}
          <div className={styles.dateFilter}>
            <label htmlFor="startDate">Début :</label>
            <input 
              type="date" 
              id="startDate"
              value={startDate} 
              onChange={(e) => setStartDate(e.target.value)} 
              className={styles.dateInput}
            />
            <label htmlFor="endDate">Fin :</label>
            <input 
              type="date" 
              id="endDate"
              value={endDate} 
              onChange={(e) => setEndDate(e.target.value)} 
              className={styles.dateInput}
            />
            <button onClick={() => goToPage(0)} className={styles.searchButton}>
              Rechercher
            </button>
          </div>

          <ExcelTable data={currentTransactions} />

          {/* Pagination Controls */}
          <div className={styles.pagination}>
            <button 
              onClick={() => goToPage(currentPage - 1)} 
              disabled={currentPage === 0}
              className={styles.paginationButton}
            >
              &laquo; Précédent
            </button>
            <span className={styles.pageInfo}>{`${currentPage + 1} / ${totalPages}`}</span>
            <button 
              onClick={() => goToPage(currentPage + 1)} 
              disabled={currentPage === totalPages - 1}
              className={styles.paginationButton}
            >
              Suivant &raquo;
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllTransactionsVault;
