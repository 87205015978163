import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation for routing
import { FaHome, FaChartPie, FaUtensils, FaLock, FaUsers, FaBriefcase, FaHandshake, FaCog, FaSignOutAlt, FaSearch, FaQuestionCircle, FaBars } from 'react-icons/fa';
import styles from './SideBar.module.css';
import { useNavigate } from "react-router-dom";
function Sidebar() {
  const location = useLocation(); // Get the current route location
  const [activeItem, setActiveItem] = useState(location.pathname); // Set initial active item based on route
  const [isOpen, setIsOpen] = useState(true); // Sidebar open/close state
  const navigate = useNavigate();
  // Function to handle menu item clicks
  const handleMenuItemClick = (item) => {
    setActiveItem(item);
  };

  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const handleProfileClick = () => {
    window.location.href = '/profile'; // Redirects to the profile page
  };
  const handleLogout = () => {
    // Clear any authentication data from localStorage, sessionStorage, or state
    localStorage.removeItem('authToken'); // Example: remove authentication token
    sessionStorage.removeItem('authToken'); // Example: remove authentication token from sessionStorage
    
    // Redirect to the sign-in page
    navigate('/'); // Adjust the path to your actual sign-in route
  };

  return (
    <div className={`${styles.sidebar} ${isOpen ? styles.open : styles.closed}`}>
      {/* Toggle Button */}
      <div className={styles.toggleButton} onClick={toggleSidebar}>
        <FaBars className={styles.toggleIcon} />
      </div>
      <img src={`${process.env.PUBLIC_URL}/assets/img/LogoOptimizi.png`} alt="profile" className={styles.LogoImage} />

      {/* Profile Section */}
      {isOpen && (
        <div className={styles.profileSection}>
        <Link to="/Profile" className={styles.profilePicContainer}>
  <img src={`${process.env.PUBLIC_URL}/assets/img/avatar.png`} alt="profile" className={styles.profilePic} />
</Link>

          <div className={styles.userInfo}>
            <p className={styles.userName}>StartUpTunisie</p>
            <p className={styles.userCompany}>Propriétaire</p>
          </div>
        </div>
      )}
 
      {/* Search Section */}
      {isOpen && (
        <div className={styles.searchSection}>
          <div className={styles.searchBar}>
            <FaSearch className={styles.searchIcon} />
            <input type="text" placeholder="Search here..." className={styles.searchInput} />
          </div>
        </div>
      )}

      {/* Main Menu Section */}
      <div className={styles.menuSection}>
        <p className={`${styles.menuTitle} ${!isOpen && styles.hideTitle}`}>MENU PRINCIPAL</p>
        <Link to="/Dash" className={`${styles.menuItem} ${activeItem === '/Dash' ? styles.active : ''}`} onClick={() => handleMenuItemClick('/Dash')}>
          <FaHome className={styles.icon} />
          {isOpen && <span>Home</span>}
        </Link>
        
        <Link to="/Analytics" className={`${styles.menuItem} ${activeItem === '/analytics' ? styles.active : ''}`} onClick={() => handleMenuItemClick('/analytics')}>
          <FaChartPie className={styles.icon} />
          {isOpen && <span>Analytique</span>}
        </Link>
        <Link 
  to="/vault" 
  className={`${styles.menuItem} ${activeItem === '/vault' || activeItem === '/AllTransactionsVault'|| activeItem ===  '/Enciasse' || activeItem ==='/Decaissement' ? styles.active : ''}`} 
  onClick={() => handleMenuItemClick('/vault')}
>
  <FaLock className={styles.icon} />
  {isOpen && <span>Vault</span>}
</Link>

        <Link to="/rh" className={`${styles.menuItem} ${activeItem === '/rh' ? styles.active : ''}`} onClick={() => handleMenuItemClick('/rh')}>
          <FaUsers className={styles.icon} />
          {isOpen && <span>RH</span>}
        </Link>
        <Link to="/foodcost" className={`${styles.menuItem} ${activeItem === '/foodcost' ? styles.active : ''}`} onClick={() => handleMenuItemClick('/foodcost')}>
          <FaUtensils className={styles.icon} />
          {isOpen && <span>Food Cost</span>}
        </Link>
        <Link
  to="/fournisseurs"
  className={`${styles.menuItem} ${
    activeItem === '/fournisseurs' || activeItem === '/FullFournisseur'|| activeItem.startsWith('/fournisseurs/')
      ? styles.active
      : ''
  }`}
  onClick={() => handleMenuItemClick('/fournisseurs')}
>
  <FaBriefcase className={styles.icon} />
  {isOpen && <span>Fournisseurs</span>}
</Link>

        <Link to="/partenaires" className={`${styles.menuItem} ${activeItem === '/partenaires' ? styles.active : ''}`} onClick={() => handleMenuItemClick('/partenaires')}>
          <FaHandshake className={styles.icon} />
          {isOpen && <span>Partenaires</span>}
        </Link>
      </div>

      {/* Settings Section */}
      {isOpen && (
        <div className={styles.settingsSection}>
          <p className={styles.menuTitle}>PARAMÈTRES</p>
          
          <Link to="/Profile" className={`${styles.menuItem} ${activeItem === '/Profile' ? styles.active : ''}`} onClick={() => handleMenuItemClick('/Profile')}>
            <FaCog className={styles.icon} />
            <span>Paramètres du compte</span>
          </Link>
          <Link to="/help-center" className={`${styles.menuItem} ${activeItem === '/help-center' ? styles.active : ''}`} onClick={() => handleMenuItemClick('/help-center')}>
            <FaQuestionCircle className={styles.icon} />
            <span>Help Center</span>
          </Link>
        </div>
      )}

      {/* Logout Section */}
      <div className={styles.logoutSection}>
        <div className={`${styles.menuItem} ${activeItem === 'disconnect' ? styles.active : ''}`} onClick={() => handleLogout('disconnect')}>
          <FaSignOutAlt className={styles.icon} />
          {isOpen && <span>Déconnecter</span>}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
