export const partnersData = [
    {
      name: 'Jumia',
      image: `${process.env.PUBLIC_URL}assets/img/Jumia.png`,
      contribution: '5,000 Dt',
      transactions: [
        {
          date: '2024-01-01',
          montant: 500.00, // Gross amount for this transaction
          commission: 0.10, // 10% commission
          tva: 0.19, // 19% VAT
          droitsDeTimbre: 5, // Fixed fee for stamp duties
          encaissement: 480.00, // Total collected for this transaction
          montantRecu: 485.00, // Total amount received after deductions
          ecart: function() {
            return this.montant - (this.encaissement + this.droitsDeTimbre + (this.montant * this.commission) + (this.montant * this.tva));
          },
        },
        {
          date: '2024-01-05',
          montant: 500.00,
          commission: 0.10,
          tva: 0.19,
          droitsDeTimbre: 5,
          encaissement: 485.00,
          montantRecu: 490.00,
          ecart: function() {
            return this.montant - (this.encaissement + this.droitsDeTimbre + (this.montant * this.commission) + (this.montant * this.tva));
          },
        },
      ],
    },
    {
      name: 'Sodexo',
      image: `${process.env.PUBLIC_URL}/assets/img/Sodexo.png`,
      contribution: '3,000 Dt',
      transactions: [
        {
          date: '2024-01-03',
          montant: 150.00,
          commission: 0.10,
          tva: 0.19,
          droitsDeTimbre: 3,
          encaissement: 140.00,
          montantRecu: 144.00,
          ecart: function() {
            return this.montant - (this.encaissement + this.droitsDeTimbre + (this.montant * this.commission) + (this.montant * this.tva));
          },
        },
        {
          date: '2024-01-06',
          montant: 100.00,
          commission: 0.10,
          tva: 0.19,
          droitsDeTimbre: 2,
          encaissement: 90.00,
          montantRecu: 94.00,
          ecart: function() {
            return this.montant - (this.encaissement + this.droitsDeTimbre + (this.montant * this.commission) + (this.montant * this.tva));
          },
        },
      ],
    },
    {
      name: 'Pluxee',
      image: `${process.env.PUBLIC_URL}/assets/img/Pluxee.png`,
      contribution: '2,000 Dt',
      transactions: [
        {
          date: '2024-01-02',
          montant: 600.00,
          commission: 0.10,
          tva: 0.19,
          droitsDeTimbre: 4,
          encaissement: 570.00,
          montantRecu: 574.00,
          ecart: function() {
            return this.montant - (this.encaissement + this.droitsDeTimbre + (this.montant * this.commission) + (this.montant * this.tva));
          },
        },
        {
          date: '2024-01-07',
          montant: 480.00,
          commission: 0.10,
          tva: 0.19,
          droitsDeTimbre: 4,
          encaissement: 460.00,
          montantRecu: 464.00,
          ecart: function() {
            return this.montant - (this.encaissement + this.droitsDeTimbre + (this.montant * this.commission) + (this.montant * this.tva));
          },
        },
      ],
    },
    {
      name: 'Glovo',
      image: `${process.env.PUBLIC_URL}/assets/img/Glovo.png`,
      contribution: '4,000 Dt',
      transactions: [
        {
          date: '2024-01-04',
          montant: 105.00,
          commission: 0.10,
          tva: 0.19,
          droitsDeTimbre: 1,
          encaissement: 100.00,
          montantRecu: 102.00,
          ecart: function() {
            return this.montant - (this.encaissement + this.droitsDeTimbre + (this.montant * this.commission) + (this.montant * this.tva));
          },
        },
        {
          date: '2024-01-08',
          montant: 600.00,
          commission: 0.10,
          tva: 0.19,
          droitsDeTimbre: 5,
          encaissement: 580.00,
          montantRecu: 585.00,
          ecart: function() {
            return this.montant - (this.encaissement + this.droitsDeTimbre + (this.montant * this.commission) + (this.montant * this.tva));
          },
        },
      ],
    },
  ];
  